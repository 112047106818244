<template>
  <div class="ps-3 pe-3 " style="max-width: 1000px;">
      <div class="d-flex">
       
        <!-- <div class="ms-2 mb-3 gray-box pb-1 pt-2 ps-3 pe-3" style="height:fit-content;width: fit-content;">{{user.first_name}} {{user.last_name}}</div> -->
        
      </div>
   
      <div class="row">
        <div class="balance-ref shadow-lg col-12 col-lg-9 col-md-8 col-sm-12 bg-dark" style="position: relative;">
          
          <svg class="w-100 h-100" style="position: absolute; opacity: 0.3;" width="283" height="201" viewBox="0 0 283 201" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M41.4017 60.1582C67.7501 68.9432 114.389 100.685 102.849 108.89C81.5064 124.067 -31.874 -44.019 13.7069 -57.1965C59.2879 -70.3741 159.105 81.5406 162.566 178.259C166.028 274.977 22.0728 174.032 102.849 8.93956C183.626 -156.153 193.435 80.7945 181.607 116.1C169.779 151.406 151.604 193.425 124.198 197.155C96.7914 200.884 110.35 73.5841 282 1.23194" stroke="#C3BCC9" stroke-opacity="0.43" stroke-width="5"/>
          </svg>


          <div class="row pt-3  align-items-start  justify-content-between"> 
            <div class="balance-available  ms-2 pt-2 pb-1" > {{$t('dashboard.available_balance') }} </div>
            
            
            <div style="z-index: 99;" class="balance-available-value pt-1 pb-1 cursor-pointer " @click="to('/profile')">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z" fill="white"/>
                <path d="M17.08 14.1499C14.29 12.2899 9.73996 12.2899 6.92996 14.1499C5.65996 14.9999 4.95996 16.1499 4.95996 17.3799C4.95996 18.6099 5.65996 19.7499 6.91996 20.5899C8.31996 21.5299 10.16 21.9999 12 21.9999C13.84 21.9999 15.68 21.5299 17.08 20.5899C18.34 19.7399 19.04 18.5999 19.04 17.3599C19.03 16.1299 18.34 14.9899 17.08 14.1499Z" fill="white"/>
                </svg>
              </div>
          </div>
          <div class="row pb-1 pt-4 text-balance-main text-center ms-2"> 
            {{user?.total_available?.toLocaleString('en')}} {{$t('tasks.valuta') }}
          </div>

          <div class="row pt-1 pb-4  text-center ps-1"> 
            <div class="balance-available-value pt-1 pb-1" >{{$t('dashboard.pribil_segodnya') }} +{{user.revenue_today}} $</div>
          </div>
        </div>

        <div class="col-12  col-lg-3  col-md-4  col-sm-12 pt-1 ">
          <div class="row ">
            <div class=" col-md-12 col-6 mt-3 mt-md-0">
              <div @click="toggleTopUpCrypto" class="gray-box elevation-x cursor-pointer align-items-center justify-content-between d-flex">
                  <div class="digit"> <span class="bl">{{$t('dashboard.Top_up_dep') }}</span>   </div>
                  <div class="image-icon">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="28" width="28" height="28" rx="14" transform="rotate(-90 0 28)" fill="white"/>
                      <path d="M9 14H19" stroke="black" stroke-linecap="round"/>
                      <path d="M14 9L14 19" stroke="black" stroke-linecap="round"/>
                    </svg>
                  </div>  
              </div>
            </div>
            <div class=" col-md-12 col-6 mt-3 ">
              <div @click="withdraw(3)"  class="gray-box lign-items-center cursor-pointer justify-content-between d-flex">
                  <div class="digit"> <span class="bl">{{$t('dashboard.withdrawal') }}</span>   </div>
                  <div class="image-icon">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="28" width="28" height="28" rx="14" transform="rotate(-90 0 28)" fill="white"/>
                      <path d="M11 18L18 11M18 11L11 11M18 11L18 18" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>  
              </div>
            </div>
            <div class=" col-12 mt-3 ">
              <div @click="toggleReinvest()"   style="     " class="gray-box  align-items-center cursor-pointer justify-content-between d-flex">
                  <div class="digit"> <span class="bl">{{$t('dashboard.transfer') }}</span>   </div>
                  <div class="image-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.8101 6.24998H20.1701C19.9801 5.97998 19.7801 5.72998 19.5701 5.47998L18.8101 6.24998Z" fill="#292D32"/>
                      <path d="M18.52 4.42001C18.27 4.21001 18.02 4.01001 17.75 3.82001V5.18001L18.52 4.42001Z" fill="#292D32"/>
                      <path d="M19.58 5.48L22.53 2.53C22.82 2.24 22.82 1.76 22.53 1.47C22.24 1.18 21.76 1.18 21.47 1.47L18.52 4.42C18.9 4.75 19.25 5.11 19.58 5.48Z" fill="#292D32"/>
                      <path d="M17.75 3C17.75 2.59 17.41 2.25 17 2.25C16.6 2.25 16.28 2.57 16.26 2.96C16.78 3.21 17.28 3.49 17.75 3.82V3Z" fill="#292D32"/>
                      <path d="M21.7499 7C21.7499 6.59 21.4099 6.25 20.9999 6.25H20.1699C20.4999 6.72 20.7899 7.22 21.0299 7.74C21.4299 7.72 21.7499 7.4 21.7499 7Z" fill="#292D32"/>
                      <path d="M12.75 14.75H13.05C13.44 14.75 13.75 14.4 13.75 13.97C13.75 13.43 13.6 13.35 13.26 13.23L12.75 13.05V14.75Z" fill="#292D32"/>
                      <path d="M21.04 7.74C21.03 7.74 21.02 7.75 21 7.75H17C16.9 7.75 16.81 7.73 16.71 7.69C16.53 7.61 16.38 7.47 16.3 7.28C16.27 7.19 16.25 7.1 16.25 7V3C16.25 2.99 16.26 2.98 16.26 2.96C14.96 2.35 13.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 10.48 21.65 9.04 21.04 7.74ZM13.75 11.82C14.39 12.04 15.25 12.51 15.25 13.98C15.25 15.23 14.26 16.26 13.05 16.26H12.75V16.51C12.75 16.92 12.41 17.26 12 17.26C11.59 17.26 11.25 16.92 11.25 16.51V16.26H11.17C9.84 16.26 8.75 15.14 8.75 13.76C8.75 13.34 9.09 13 9.5 13C9.91 13 10.25 13.34 10.25 13.75C10.25 14.3 10.66 14.75 11.17 14.75H11.25V12.53L10.25 12.18C9.61 11.96 8.75 11.49 8.75 10.02C8.75 8.77 9.74 7.74 10.95 7.74H11.25V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V7.75H12.83C14.16 7.75 15.25 8.87 15.25 10.25C15.25 10.66 14.91 11 14.5 11C14.09 11 13.75 10.66 13.75 10.25C13.75 9.7 13.34 9.25 12.83 9.25H12.75V11.47L13.75 11.82Z" fill="#292D32"/>
                      <path d="M10.25 10.03C10.25 10.57 10.4 10.65 10.74 10.77L11.25 10.95V9.25H10.95C10.57 9.25 10.25 9.6 10.25 10.03Z" fill="#292D32"/>
                      </svg>
                      
                  </div>  
              </div>
            </div>
        </div>
        </div>
        
      </div>
      
     <div class="row  pb-0 pt-2" v-if="!user?.telegram_connected && false">
      <telegram />
    </div>
    <div class="mt-5"></div>

   
    <div class="infor-line m-3 d-flex align-items-center justify-content-between">
      <div>{{$t('dashboard.total_deposit') }}</div>
      <div  @click="to('/inapp/top-up')"><span> {{user?.deposit?.toLocaleString('en')}} $</span> </div>
    </div>

    <div class="infor-line m-3 d-flex align-items-center justify-content-between">
      <div>{{$t('dashboard.withdrawn') }}</div>
      <div  @click="to('/inapp/top-up')"><span>{{user?.withdrawed?.toLocaleString('en')}} $ </span> </div>
    </div>


    <div class="infor-line m-3 d-flex align-items-center justify-content-between">
      <div>{{$t('dashboard.team_profit') }}</div>
      <div  ><span> {{ total_cashback_friends.toLocaleString('en') }} $</span> </div>
    </div>

    <div class="infor-line  m-3 d-flex align-items-center justify-content-between">
      <div>{{$t('dashboard.invest_plan') }}  </div>
      <div class="ps-5 p-3 cursor-pointer"  @click="to('/invest')"> 
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2075_728)">
          <path d="M-0.000244141 17.6074L23.6173 17.6074M23.6173 17.6074L17.6068 11.597M23.6173 17.6074L17.6068 23.6178" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_2075_728">
          <rect y="34" width="34" height="34" rx="17" transform="rotate(-90 0 34)" fill="white"/>
          </clipPath>
          </defs>
        </svg>
            
      </div>
    </div>


    <!-- <div class="infor-line  m-3 d-flex align-items-center justify-content-between">
      <div>Команда</div>
      <div class="ps-5 p-3 cursor-pointer" @click="to('/tasks')"> 
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2075_728)">
          <path d="M-0.000244141 17.6074L23.6173 17.6074M23.6173 17.6074L17.6068 11.597M23.6173 17.6074L17.6068 23.6178" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_2075_728">
          <rect y="34" width="34" height="34" rx="17" transform="rotate(-90 0 34)" fill="white"/>
          </clipPath>
          </defs>
        </svg>
            
      </div>
    </div> -->


    <div class="infor-line  m-3 d-flex align-items-center justify-content-between">
      <div>{{$t('dashboard.settings') }}   </div>
      <div class="ps-5 p-3 cursor-pointer" @click="to('/inapp/profile')"> 
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2075_728)">
          <path d="M-0.000244141 17.6074L23.6173 17.6074M23.6173 17.6074L17.6068 11.597M23.6173 17.6074L17.6068 23.6178" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_2075_728">
          <rect y="34" width="34" height="34" rx="17" transform="rotate(-90 0 34)" fill="white"/>
          </clipPath>
          </defs>
        </svg>
            
      </div>
    </div>




    <div class="green-box-gradient mt-3 " style="display: none;">
        <!-- <div class="progress">
          <div class="progress-load" :style="`width:${user.days/31*100}%`"></div>
        </div> -->
        <div class="days"> {{$t('dashboard.fin_plan') }}  </div>
        <div class="sign"> {{$t('dashboard.pribil_plan') }}</div>  
        <div @click="move(user.financeplanlink)" class="sign " style="border: 1px solid rgba(255,255,255,0.4); opacity:0.9; font-weight:600; padding:8px; background:rgba(255,255,255,0.3); border-radius:15px; color:#fff;"> {{$t('dashboard.open_table') }}</div>  
    </div>
   

    <div  class="gray-box mt-6 bg-transparent position-relative">
    <h2 class="mb-4">  {{$t('dashboard.active_deposits') }}  </h2>

    <div class="digit"> </div> 
      <div  class="p-2 cursor-pointer" style="position:absolute; right:10px;top:20px" :style="showDeposits ? 'transform:rotate(180deg)':null ">
        
      </div>
      
      
      <div class="pt-3 mt-2 mb-8" style="border-top:1px solid gray;" v-if="showDeposits">
        <div v-if="transactions.length===0">
          <div class=" mb-5">
            <div class="w-100 text-start" style="opacity:0.6"> {{$t('dashboard.empty_deps.0') }} </div> 
            <div class="mt-3" style="width: fit-content;">
              <div @click="toggleTopUpCrypto" class="gray-box elevation-x cursor-pointer align-items-center justify-content-between d-flex">
                  <div class="digit"> <span class="bl">{{$t('dashboard.Top_up_dep') }}</span>   </div>
                  <div class="image-icon">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="28" width="28" height="28" rx="14" transform="rotate(-90 0 28)" fill="white"/>
                      <path d="M9 14H19" stroke="black" stroke-linecap="round"/>
                      <path d="M14 9L14 19" stroke="black" stroke-linecap="round"/>
                    </svg>
                  </div>  
              </div>
            </div>

        </div>
            
        </div>
        <div class=" mt-3 " :class="[t.blocked ? '': null, t.close ? '': null,] " v-for="t in transactions" v-bind:key="t.id">
          <div class="row mt-3 ps-2 pe-2">
            <div class="col textDepositValue"> 
              <span style="font-weight: 700; font-size:25px">{{t.value.toLocaleString('en')}} {{$t('dashboard.valuta') }}</span>  
              <sup style="opacity:0.5;">{{$t('dashboard.date') }} {{t.date}}</sup>
            </div>
            <div  class="mt-2">
              <!-- <span  class="">{{$t('dashboard.account') }}</span>   -->
              <span class="source p-1 ps-3 pe-3 ">{{ t.portfolio.name }} </span> </div>
          </div>

          
         
          <div class="row pb-2 ps-2 mt-3 pe-2">
            <div class="col" v-if="!blocked">
              <div v-if="t.close"  class="" style="padding:5px; opacity:0.5; margin-bottom:8px;">
                 {{$t('dashboard.profit_dep') }} </div>
              <div v-if="!t.close"  class="" style="padding:5px; opacity:0.5; margin-bottom:8px;">
         
                    <div>

                      <span class="circle pulse" >

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 15C4.06 15 3.19 15.33 2.5 15.88C1.58 16.61 1 17.74 1 19C1 19.75 1.21 20.46 1.58 21.06C2.27 22.22 3.54 23 5 23C6.01 23 6.93 22.63 7.63 22C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15ZM7.07 18.57L4.94 20.54C4.8 20.67 4.61 20.74 4.43 20.74C4.24 20.74 4.05 20.67 3.9 20.52L2.91 19.53C2.62 19.24 2.62 18.76 2.91 18.47C3.2 18.18 3.68 18.18 3.97 18.47L4.45 18.95L6.05 17.47C6.35 17.19 6.83 17.21 7.11 17.51C7.39 17.81 7.37 18.29 7.07 18.57Z" fill="#74BE61"/>
                          <path d="M14.85 3.95005V7.75005H13.35V3.95005C13.35 3.68005 13.11 3.55005 12.95 3.55005C12.9 3.55005 12.85 3.56005 12.8 3.58005L4.87 6.57005C4.34 6.77005 4 7.27005 4 7.84005V8.51005C3.09 9.19005 2.5 10.28 2.5 11.51V7.84005C2.5 6.65005 3.23 5.59005 4.34 5.17005L12.28 2.17005C12.5 2.09005 12.73 2.05005 12.95 2.05005C13.95 2.05005 14.85 2.86005 14.85 3.95005Z" fill="#74BE61"/>
                          <path d="M21.4997 14.5V15.5C21.4997 15.77 21.2897 15.99 21.0097 16H19.5497C19.0197 16 18.5397 15.61 18.4997 15.09C18.4697 14.78 18.5897 14.49 18.7897 14.29C18.9697 14.1 19.2197 14 19.4897 14H20.9997C21.2897 14.01 21.4997 14.23 21.4997 14.5Z" fill="#74BE61"/>
                          <path d="M19.48 12.95H20.5C21.05 12.95 21.5 12.5 21.5 11.95V11.51C21.5 9.44 19.81 7.75 17.74 7.75H6.26C5.41 7.75 4.63 8.03 4 8.51C3.09 9.19 2.5 10.28 2.5 11.51V13.29C2.5 13.67 2.9 13.91 3.26 13.79C3.82 13.6 4.41 13.5 5 13.5C8.03 13.5 10.5 15.97 10.5 19C10.5 19.72 10.31 20.51 10.01 21.21C9.85 21.57 10.1 22 10.49 22H17.74C19.81 22 21.5 20.31 21.5 18.24V18.05C21.5 17.5 21.05 17.05 20.5 17.05H19.63C18.67 17.05 17.75 16.46 17.5 15.53C17.3 14.77 17.54 14.03 18.04 13.55C18.41 13.17 18.92 12.95 19.48 12.95ZM14 12.75H9C8.59 12.75 8.25 12.41 8.25 12C8.25 11.59 8.59 11.25 9 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z" fill="#74BE61"/>
                        </svg>

                      </span>
                      <span class="ms-2"> {{$t('dashboard.deposit_active.0') }}  {{t.dummy*100}}% {{$t('dashboard.deposit_active.1') }}</span>      
                  </div>
                  <div v-if="t.dummy<1" class="p-2 mt-2 mb-2" style="border: 1px dotted red; border-radius:10px;"  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.8096 6.25047H20.1696C19.9796 5.98047 19.7796 5.73047 19.5696 5.48047L18.8096 6.25047Z" fill="#FF5C5C"/>
                      <path d="M18.52 4.42031C18.27 4.21031 18.02 4.01031 17.75 3.82031V5.18031L18.52 4.42031Z" fill="#FF5C5C"/>
                      <path d="M19.5795 5.48043L22.5295 2.53043C22.8195 2.24043 22.8195 1.76043 22.5295 1.47043C22.2395 1.18043 21.7595 1.18043 21.4695 1.47043L18.5195 4.42043C18.8995 4.75043 19.2495 5.11043 19.5795 5.48043Z" fill="#FF5C5C"/>
                      <path d="M17.7498 3C17.7498 2.59 17.4098 2.25 16.9998 2.25C16.5998 2.25 16.2798 2.57 16.2598 2.96C16.7798 3.21 17.2798 3.49 17.7498 3.82V3Z" fill="#FF5C5C"/>
                      <path d="M21.7499 7C21.7499 6.59 21.4099 6.25 20.9999 6.25H20.1699C20.4999 6.72 20.7899 7.22 21.0299 7.74C21.4299 7.72 21.7499 7.4 21.7499 7Z" fill="#FF5C5C"/>
                      <path d="M12.75 14.7498H13.05C13.44 14.7498 13.75 14.3998 13.75 13.9698C13.75 13.4298 13.6 13.3498 13.26 13.2298L12.75 13.0498V14.7498Z" fill="#FF5C5C"/>
                      <path d="M21.04 7.74C21.03 7.74 21.02 7.75 21 7.75H17C16.9 7.75 16.81 7.73 16.71 7.69C16.53 7.61 16.38 7.47 16.3 7.28C16.27 7.19 16.25 7.1 16.25 7V3C16.25 2.99 16.26 2.98 16.26 2.96C14.96 2.35 13.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 10.48 21.65 9.04 21.04 7.74ZM13.75 11.82C14.39 12.04 15.25 12.51 15.25 13.98C15.25 15.23 14.26 16.26 13.05 16.26H12.75V16.51C12.75 16.92 12.41 17.26 12 17.26C11.59 17.26 11.25 16.92 11.25 16.51V16.26H11.17C9.84 16.26 8.75 15.14 8.75 13.76C8.75 13.34 9.09 13 9.5 13C9.91 13 10.25 13.34 10.25 13.75C10.25 14.3 10.66 14.75 11.17 14.75H11.25V12.53L10.25 12.18C9.61 11.96 8.75 11.49 8.75 10.02C8.75 8.77 9.74 7.74 10.95 7.74H11.25V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V7.75H12.83C14.16 7.75 15.25 8.87 15.25 10.25C15.25 10.66 14.91 11 14.5 11C14.09 11 13.75 10.66 13.75 10.25C13.75 9.7 13.34 9.25 12.83 9.25H12.75V11.47L13.75 11.82Z" fill="#FF5C5C"/>
                      <path d="M10.25 10.03C10.25 10.57 10.4 10.65 10.74 10.77L11.25 10.95V9.25H10.95C10.57 9.25 10.25 9.6 10.25 10.03Z" fill="#FF5C5C"/>
                      </svg>
                      

                    <span class="ms-2"> {{$t('dashboard.deposit_active.2') }}  {{t.dummy*100}}%. <br>  {{$t('dashboard.support') }}  </span>     
                  </div>

                  <div v-if="t.dummy>1" class=" mt-2 mb-2" style=" border-radius:10px;"  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.8096 6.25047H20.1696C19.9796 5.98047 19.7796 5.73047 19.5696 5.48047L18.8096 6.25047Z" fill="#74BE61"/>
                      <path d="M18.52 4.42031C18.27 4.21031 18.02 4.01031 17.75 3.82031V5.18031L18.52 4.42031Z" fill="#74BE61"/>
                      <path d="M19.5795 5.48043L22.5295 2.53043C22.8195 2.24043 22.8195 1.76043 22.5295 1.47043C22.2395 1.18043 21.7595 1.18043 21.4695 1.47043L18.5195 4.42043C18.8995 4.75043 19.2495 5.11043 19.5795 5.48043Z" fill="#74BE61"/>
                      <path d="M17.7498 3C17.7498 2.59 17.4098 2.25 16.9998 2.25C16.5998 2.25 16.2798 2.57 16.2598 2.96C16.7798 3.21 17.2798 3.49 17.7498 3.82V3Z" fill="#74BE61"/>
                      <path d="M21.7499 7C21.7499 6.59 21.4099 6.25 20.9999 6.25H20.1699C20.4999 6.72 20.7899 7.22 21.0299 7.74C21.4299 7.72 21.7499 7.4 21.7499 7Z" fill="#74BE61"/>
                      <path d="M12.75 14.7498H13.05C13.44 14.7498 13.75 14.3998 13.75 13.9698C13.75 13.4298 13.6 13.3498 13.26 13.2298L12.75 13.0498V14.7498Z" fill="#74BE61"/>
                      <path d="M21.04 7.74C21.03 7.74 21.02 7.75 21 7.75H17C16.9 7.75 16.81 7.73 16.71 7.69C16.53 7.61 16.38 7.47 16.3 7.28C16.27 7.19 16.25 7.1 16.25 7V3C16.25 2.99 16.26 2.98 16.26 2.96C14.96 2.35 13.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 10.48 21.65 9.04 21.04 7.74ZM13.75 11.82C14.39 12.04 15.25 12.51 15.25 13.98C15.25 15.23 14.26 16.26 13.05 16.26H12.75V16.51C12.75 16.92 12.41 17.26 12 17.26C11.59 17.26 11.25 16.92 11.25 16.51V16.26H11.17C9.84 16.26 8.75 15.14 8.75 13.76C8.75 13.34 9.09 13 9.5 13C9.91 13 10.25 13.34 10.25 13.75C10.25 14.3 10.66 14.75 11.17 14.75H11.25V12.53L10.25 12.18C9.61 11.96 8.75 11.49 8.75 10.02C8.75 8.77 9.74 7.74 10.95 7.74H11.25V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V7.75H12.83C14.16 7.75 15.25 8.87 15.25 10.25C15.25 10.66 14.91 11 14.5 11C14.09 11 13.75 10.66 13.75 10.25C13.75 9.7 13.34 9.25 12.83 9.25H12.75V11.47L13.75 11.82Z" fill="#74BE61"/>
                      <path d="M10.25 10.03C10.25 10.57 10.4 10.65 10.74 10.77L11.25 10.95V9.25H10.95C10.57 9.25 10.25 9.6 10.25 10.03Z" fill="#74BE61"/>
                      </svg>
                      

                    <span class="ms-2"> {{$t('dashboard.deposit_active.3') }}  {{t.dummy*100}}%  {{$t('dashboard.deposit_active.4') }}  </span>     
                  </div>

                  <div class="pb-2" v-if="t.days>0"> 
                    <div class="col-10 me-0 pe-0" >
                      <template v-if="t.days>1">
      
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.89 3.45H9.11001C8.71001 3.45 8.39001 3.13 8.39001 2.73C8.39001 2.33 8.71001 2 9.11001 2H14.89C15.29 2 15.61 2.32 15.61 2.72C15.61 3.12 15.29 3.45 14.89 3.45Z" fill="#292D32"/>
                          <path d="M14 19.97V17.03C14 15.22 15.22 14 17.03 14H19.97C20.2 14 20.42 14.02 20.63 14.06C20.65 13.82 20.67 13.58 20.67 13.33C20.67 8.53999 16.78 4.64999 12 4.64999C7.21996 4.64999 3.32996 8.53999 3.32996 13.33C3.32996 18.11 7.21996 22 12 22C12.85 22 13.66 21.86 14.44 21.64C14.16 21.17 14 20.61 14 19.97ZM12.75 13C12.75 13.41 12.41 13.75 12 13.75C11.59 13.75 11.25 13.41 11.25 13V7.99999C11.25 7.58999 11.59 7.24999 12 7.24999C12.41 7.24999 12.75 7.58999 12.75 7.99999V13Z" fill="#292D32"/>
                          <path d="M19.97 15H17.04C15.76 15 15 15.76 15 17.03V19.97C15 21.24 15.76 22 17.04 22H19.97C21.24 22 22 21.24 22 19.97V17.03C22 15.76 21.24 15 19.97 15ZM17.92 20.06C17.92 20.38 17.66 20.64 17.33 20.64C17.01 20.64 16.75 20.38 16.75 20.06V16.94C16.75 16.62 17.01 16.36 17.33 16.36C17.66 16.36 17.92 16.62 17.92 16.94V20.06ZM20.25 20.06C20.25 20.38 19.99 20.64 19.67 20.64C19.35 20.64 19.08 20.38 19.08 20.06V16.94C19.08 16.62 19.35 16.36 19.67 16.36C19.99 16.36 20.25 16.62 20.25 16.94V20.06Z" fill="#292D32"/>
                          </svg>
                          <span class='ms-2'>  {{ t.days }} {{$t('dashboard.sum_day') }} {{$t('dashboard.remaining_days') }} </span>
                      </template>
                      <template v-else>
                        <svg @click="myAlert()" style="margin-right:5px; padding: 1px; margin-start:2px;" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 0C4.70666 0 0 4.70666 0 10.5C0 16.2933 4.70666 21 10.5 21C16.2933 21 21 16.2933 21 10.5C21 4.70666 16.2933 0 10.5 0ZM10.5 0.954545C15.7775 0.954545 20.0455 5.22254 20.0455 10.5C20.0455 15.7775 15.7775 20.0455 10.5 20.0455C5.22254 20.0455 0.954545 15.7775 0.954545 10.5C0.954545 5.22254 5.22254 0.954545 10.5 0.954545ZM10.5 4.77273C8.66052 4.77273 7.15909 6.27416 7.15909 8.11364C7.1582 8.17688 7.16988 8.23967 7.19346 8.29836C7.21705 8.35705 7.25206 8.41047 7.29647 8.45551C7.34088 8.50055 7.39379 8.53631 7.45214 8.56072C7.51049 8.58514 7.57311 8.59771 7.63636 8.59771C7.69961 8.59771 7.76223 8.58514 7.82058 8.56072C7.87893 8.53631 7.93185 8.50055 7.97626 8.45551C8.02067 8.41047 8.05568 8.35705 8.07926 8.29836C8.10285 8.23967 8.11453 8.17688 8.11364 8.11364C8.11364 6.79003 9.17639 5.72727 10.5 5.72727C11.8236 5.72727 12.8864 6.79003 12.8864 8.11364C12.8957 9.18552 12.3202 9.7626 11.6112 10.4329C11.2566 10.768 10.8777 11.1099 10.5671 11.5366C10.2565 11.9632 10.0227 12.4951 10.0227 13.125V13.3636C10.0218 13.4269 10.0335 13.4897 10.0571 13.5484C10.0807 13.6071 10.1157 13.6605 10.1601 13.7055C10.2045 13.7505 10.2574 13.7863 10.3158 13.8107C10.3741 13.8351 10.4367 13.8477 10.5 13.8477C10.5633 13.8477 10.6259 13.8351 10.6842 13.8107C10.7426 13.7863 10.7955 13.7505 10.8399 13.7055C10.8843 13.6605 10.9193 13.6071 10.9429 13.5484C10.9665 13.4897 10.9782 13.4269 10.9773 13.3636V13.125C10.9773 12.716 11.1055 12.4115 11.3352 12.0959C11.565 11.7803 11.9033 11.4707 12.2674 11.1264C12.9943 10.4392 13.8502 9.54704 13.8409 8.11364C13.8409 8.11096 13.8409 8.10883 13.8409 8.10618C13.8368 6.27015 12.337 4.77273 10.5 4.77273ZM10.5 14.7955C10.1046 14.7955 9.78409 15.116 9.78409 15.5114C9.78409 15.9067 10.1046 16.2273 10.5 16.2273C10.8954 16.2273 11.2159 15.9067 11.2159 15.5114C11.2159 15.116 10.8954 14.7955 10.5 14.7955Z" fill="#008A53"/>
                          </svg>
                          {{$t('dashboard.new_period') }}
                      </template>
                    </div>
                </div>
               </div>

               <div v-if="t.missed_opportunity" class="row pb-3 mt-3 ps-2 pt-3 pe-2 m-1 mb-4" style=" background:#FED897; border-radius:12px;">
                <span style="font-size: 18px;font-weight:700">
                  {{$t('dashboard.earn_more.0') }}
                </span>
                <p style="">
                   {{$t('dashboard.earn_more.1') }} {{ t.missed_opportunity.days }}  {{$t('dashboard.earn_more.2') }} 
                   <span style="font-size: 18px; font-weight:700"> {{ t.missed_opportunity.messed_percent }}% 
                    {{$t('dashboard.earn_more.3') }} {{ t.missed_opportunity.messed_percent_to }}% </span>  {{$t('dashboard.earn_more.4') }} {{t.value}} $, 
                    {{$t('dashboard.earn_more.5') }} <span style="font-size: 18px; font-weight:700"> 
                      {{$t('dashboard.earn_more.6') }} <span style="color:green">+{{  t.missed_opportunity.missed_value }} $</span></span>
                      {{$t('dashboard.earn_more.7') }}
                </p>
    
                  <div  @click="getOportunity(t.id)" class="missed-oportunity-open mt-2">
                    {{$t('dashboard.buttons.rise_profit') }}
                  </div>
              </div>
              
              <div v-if="!t.close" @click="closeDeposit(t.id)" class="closeBtn-open ps-5 pe-5" > {{$t('dashboard.buttons.stop') }}</div>
              <div v-else @click="reopenDeposit(t.id)" class="closeBtn ps-5 pe-5" style="background:linear-gradient(90deg, #F5CC3A -1.34%, #12A067 109.45%);" >{{$t('dashboard.on_dep') }}</div>
              <div v-if="t.days<=0 && t.close"  @click="toProfit(t.id)" class="withdr-btn mt-2">{{$t('dashboard.send_schet') }}</div>
            </div>
            <div class="col" v-else>
              <div class="spinner-grow spinner-grow-sm me-2" role="status">
                <span class="visually-hidden">{{$t('dashboard.loading') }}</span>
              </div>
              {{$t('dashboard.in_progress') }}
            </div>
            
          </div>

          

          
           <hr>
          </div>
      </div>
      
        
  </div>
  



    
  </div>
</template>

<style scoped>
#app {

}

.infor-line{
  font-size: 22px;
  border-bottom: 1px solid #000;
  padding-bottom: 15px;
  padding-top: 15px;
}
.infor-line span{
  font-weight: 800;
}

.balance-available{
  font-style: normal;
  width: fit-content;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.6);
  font-family: "Inter", sans-serif;


}
.balance-available-value{
  /* Frame 1171275823 */
      width: fit-content;
    font-weight: 600;
    padding-left: 20px;
      padding-right: 20px;
      background: rgba(255, 255, 255, 0.09);
      border-radius: 43px;
      color: #FFFFFF;
      margin-top:10px;
      margin-right: 20px;
      font-family: "Inter", sans-serif;

}
.text-balance-main{
  /* Frame 1171275824 */

    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 1000;
    font-size: 42px;
    line-height: 39px;
    color: #FFFFFF;
}

.balance-available-value{
  /* Frame 1171275823 */
        width: fit-content;
      font-weight: 900;
      padding-left: 20px;
      padding-right: 20px;
      background: rgba(255, 255, 255, 0.09);
      border-radius: 43px;
      color: #FFFFFF;
      margin-top:10px;
      margin-right: 20px;
      font-family: "Inter", sans-serif;

}
.balance-ref{
  /* Rectangle 1 */
  font-family: "Inter", sans-serif;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 4px 53px rgba(0, 0, 0, 0.11);
  border-radius: 25px;
  padding-bottom: 10px;
}
.prefix{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.balance{

  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 63px;
  /* identical to box height */
  color: #000000;

}
.todayProfit{
  /* Frame 1171275657 */
  box-sizing: border-box;

  width: 203px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  background: #F8FCFF;
  border: 1px solid #D3D7E8;
  border-radius: 14px;

}
.profitTodayHeader{
  /* Frame 1171275658 */



font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
/* identical to box height */

color: #8D9D98;


}
.profitTodayText{

font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.01em;

color: #000000;

}
.greenLine{
  /* Line 27 */
  border-left: 3px solid #242121;
  margin-top:auto;
  margin-bottom:auto;
}
.gray-box{
  /* Frame 1171275659 */

/* Auto layout */
  padding: 15px;
  gap: 10px;

  width: 100%;
  background-color: #efefef;
  border-radius: 15px;

}
.gray-box .bl{
  color: #000;
  font-weight: 500;
  font-size: 20px;
}
.gray-box .digit{
  color: #AAA;

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.22px;
}
.gray-box .sign{
  color: #AAA;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}
.gray-box .box-header{
  color: #AAA;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}
.green-box{
  border-radius: 15px;
  border: 1px solid #242121;

  background: #FFF;
}
.green-box .text{
  color: #022F1D;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
}
.green-box .btn-my-green{
  border-radius: 42px;
  text-align: center;
  background: #242121;
  padding: 15px 0px;
  width: 100%;
  color: #FFF;
  box-shadow: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
}
.green-box-gradient{
  border-radius: 25px;
  background: linear-gradient(98.04deg, #242121 20.22%, #D75DC4 102.17%);
  display: flex;
  padding: 30px 18px;
  flex-direction: column;
  align-items: flex-center;
  gap: 10px;

}
.green-box-gradient .days{
  color: #FFF;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.green-box-gradient .sign{
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}
.progress{
  position: relative;
  border-radius: 15px;
  height: 16px;
  background: #F3F3F3;
}
.progress .progress-load{
  position: absolute;
  left:0;
  height: 100%;
  width:50%;
  border-radius: 15px;
  background: linear-gradient(90deg, #F5CC3A -1.34%, #12A067 109.45%);
}
.elementDeposit{
  border-radius: 14px;
  border: 1px solid #D3E8E1;
  background: #F9FFFD;
}
.closeBtn{
  border-radius: 15px;
  background: #242121;
  color: #fff;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}
.closeBtn-open{
  border-radius: 15px;
  border: 2px solid gray;
  color: gray;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}
.missed-oportunity-open{
  border-radius: 15px;
  border: 2px solid #F7931A;
  color: black;
  font-weight: 700;
  background-color: #F7931A;
  width: fit-content  ;
  padding: 5px 30px;
  text-align: center;
  cursor: pointer;
}

.withdr-btn{
  border-radius: 15px;
  background: #080202;
  color: #fff;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}
.redWaitT{
  border: 1px solid #E8D3D3 !important;
  background: #FFF9F9 !important;
}
.yellowWaitT{
  border: 1px solid #E5DE9B !important;
  background: #FFFEF9 !important;
}
.textDepositValue{
  color: #151515;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.source{
  border-radius: 15px;
  background: #000000;
  color: #FFF;
  width: fit-content;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
}
.circle{
  width:50px;
  height: 50px;
  border-radius: 50%;
}
.green{
  background: #66FF99;
}
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 1 0 0 0px rgba(3, 176, 87, 0.7);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(3, 176, 87, 0.2);
  }
}



</style>

<script>
import Telegram from "./components/Telegram.vue";
import setTooltip from "@/assets/js/tooltip.js";
// import RankingList from "@/examples/Cards/RankingList.vue";
// import RankingListCard from "@/examples/Cards/RankingListCard.vue";

// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import { mapMutations,mapGetters,mapActions  } from "vuex";

import {
  faHandPointer,
  faUsers,

  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      showDeposits:true,
      oportunyty:{},
      change: {
        in:{
          icon: 'fa-arrow-down',
          color: 'primary',
          sub: 'Deposit'
        },
        out:{
          icon: 'fa-arrow-up',
          color: 'danger',
          sub: 'Seccessed Withdrawn'
        },
        reinvest:{
          icon: '',
          color: 'dark',
          sub: 'Transaction'
        },
        reinvest_in:{
          icon: 'fa-arrow-left',
          color: 'info',
          sub: 'Withdrawn (Reinvest)'
        },
        reinvest_out:{
          icon: 'fa-arrow-right',
          color: 'info',
          sub: 'Deposit (Reinvest)'
        }
      },
      req: {
        in:{
          icon: 'fa-arrow-down',
          color: 'info',
          sub: 'Deposit'
        },
        out:{
          icon: 'fa-arrow-up',
          color: 'info',
          sub: 'Withdrawn Request'
        },
        reinvest:{
          icon: 'fa-arrow-up',
          color: 'dark',
          sub: 'Reinvest'
        }
      },
      iconBackground: "bg-gradient-info",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  methods: {
    ...mapMutations(["toggleConfigurator","toggleWithdraw","toggleTopUpCrypto","toggleReinvest","toggleTopUpOwn"]),
    ...mapActions(["toggleDeposit","getUserData","getTransactions","getBotsUser"]),
    withdraw(id){
      this.toggleWithdraw(id)
    },
    togleShowDeposit(){
      this.showDeposits = !this.showDeposits
    },
    move(link){
      window.location.href = link
    },
    myAlert(){
      const text = this.$t('dashboard.close_in_advance')
      window.alert(text)
    },
    getOportunity(id){
      
      const text = this.$t('dashboard.confirm_action')
      if(confirm(text)){
        this.toggleDeposit({vm:this,id:id,process:'oportunity'})
        setTimeout(() => {
          this.getTransactions()
        }, 200);
      }

    },
    toProfit(id){
      const text = this.$t('dashboard.withdraw_now')
      if(text){
        this.toggleDeposit({vm:this,id:id,process:'toprofit'})
        setTimeout(() => {
          this.getTransactions()
        }, 200);
      }

    },
    reopenDeposit(id){
     this.toggleDeposit({vm:this,id:id,process:'open'})
     setTimeout(() => {
       this.getTransactions()
     }, 200);
    },
    closeDeposit(id){
      
      const text = this.$t('dashboard.close_dep_info')
      if(confirm( text)){
        this.toggleDeposit({vm:this,id:id,process:'close'})
        setTimeout(() => {
          this.getTransactions()
        }, 200);
      }
     
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    to(path){
      this.$router.push(path);
    },
    callSupport(){
      if(this.isMobile){
        this.$toast.info("Сейчас начнется вызов", { });
        location.href='tel:+78007074730';
      }
      else{
        this.$toast.info("Вы с компьютера или с планшета, наберите номер на мобильном", { });
      }

    },
  },
  computed:{
    ...mapGetters(["user","userPlot","userTransactions","userBots","userRequests"]),
    cards(){
      return {
            title: 'Транзации',
            item:this.transactions
          }
     },
     total_cashback_friends(){
      var tcu = Math.round((this.user?.friends)*100)/100
      if (tcu<1) return 0
      else return tcu
     },
     total_cashback_used(){
      var tcu = Math.round((this.user?.deposit*this.user.currentcashback/100 - this.user.buffer)*100)/100
      if (tcu<1) return 0
      else return tcu
     },
     requests(){
      var tt = []
      this.userRequests.forEach(element => {
          var t_amount = '-'
          if (element.value){
            t_amount = element.value.toLocaleString('en')
          }

          tt.push({
            title: element.portfolio.name,
            date: `${this.req[element.in_out].sub} от ${element.date}`,
            amount: `$${t_amount}`,
            icon:  this.req[element.in_out].icon,
            color: this.req[element.in_out].color,
        })
      });
      return tt

     },

     transactions(){
      var tt = []
      this.userTransactions.forEach(element => {
         

          if (element.in_out==='in'){
            tt.push(element)
          }
         
      });
      return tt

     }
  },
  components: {
    // MiniStatisticsCard,
    // ReportsBarChart,
    Telegram,
    // GradientLineChart,
    // RankingList,
    // RankingListCard,
    // ProjectsCard,
    // TimelineList,
    // TimelineItem,
  },
   mounted() {
    setTooltip();
    this.getTransactions()
    this.getBotsUser()
  },
};
</script>
