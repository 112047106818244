<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start" style="background: transparent;">
                  <h3 class="font-weight-bolder text-dark ">
                    {{$t('signup.new_acc') }}
                  </h3>
                  <p class="mb-0">{{$t('signup.pretext') }}</p>
                </div>

               
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="getFormValues"> 
                    <soft-input
                      id="name"
                      name="name"
                      type="text"
                      :placeholder="$t('signup.vashe_imya')"
                      :value="data.name"
                      aria-label="Name"
                      :error='errors.name'
                      :success='errors.name === false'
                      
                    />
                    <soft-input
                      :value="data.email"
                      id="email"
                      type="email"
                      :placeholder="$t('signup.email')"
                      aria-label="Email"
                      :error='errors.email'
                      :success='errors.email === false'
                    />
                    <soft-input
                      :value="data.phone"
                      id="phone"
                      type="phone"
                      :placeholder="$t('signup.telephone')"
                      aria-label="Phone"
                      :error='errors.phone'
                      :success='errors.phone === false'
                    />

                    <soft-input
                      :value="data.password"
                      id="password"
                      type="password"
                      :placeholder="$t('signup.password')"
                      aria-label="Password"
                      :error='errors.password'
                      :success='errors.password === false'
                    />
                    <soft-input
                        :value="data.repeat_password"
                        id="repeat_password"
                        type="password"
                        :placeholder="$t('signup.add_password')"
                        aria-label="Password"
                        :error='errors.repeat_password'
                        :success='errors.repeat_password === false'
                      />
                    
                    
                    <div class="mt-2" >
                      <input 
                        :value="data.promo"
                        id="promo"
                        type="promo"
                        ref="clone" 
                        style="max-width: 200px; font-size: 0.8rem; height:40px; background-color: #fff; "
                        :placeholder="$t('signup.promocode')"
                        class="lav-sum-replenishment-input "
                        aria-label="promo"
                        :error='errors.promo'
                        :success='errors.promo === false'
                        :disabled="readonly_promo"  />
                    </div>


                    <soft-switch  id="flexCheckDefault"
                      name="flexCheckDefault"
                      class="font-weight-light"
                      checked>
                        {{$t('signup.apply') }} 
                      <a href="https://backend.l1st.online/media/img/dogovotoferta.pdf" class="text-dark font-weight-bolder" >{{$t('signup.pravila') }} </a> {{$t('signup.nashei') }}  
                      <a href="https://backend.l1st.online/media/img/politica.pdf" class="text-dark font-weight-bolder" >{{$t('signup.policy') }} </a>.
                      </soft-switch>

                    <div class="text-center">
                      <button
                        color="dark"
                        full-width
                        :active='loading'
                        variant="gradient"
                        class="my-3 mb-2 align-items-center justify-content-center d-flex btn-dark w-100"
                        style="height:50px; border-radius: 15px; cursor:pointer;"
                        :class='{disabled:loading}'
                        >
                        <template v-if="loading">
                          <div class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">{{$t('signup.complite_reg') }}</span>
                          </div>
                      </template>
                      <template v-else>
                        {{$t('signup.login_in') }}
                      </template>
                      </button >
                    </div>
                  </form>
                </div>
                  
                <div class="px-1 ms-3 pt-0 text-center card-footer px-lg-2">
                   
                  <p class="mx-auto mb-4 text-sm">
                    {{$t('signup.load_acc') }}
                    <router-link
                      :to="{ name: 'Sign In' }"
                        style="color:orange !important; font-weight: 600;"
                      class="text-dark font-weight-bolder"
                    >
                    {{$t('signup.vvoiti') }}
                    </router-link>
                  </p>
              </div>

              </div>
              
            </div>
            
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none"
              >
                <div
                  class="bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n7"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/signin.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
// import SoftCheckbox from "@/components/SoftCheckbox.vue";
// import SoftButton from "@/components/SoftButton.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";

import { mapMutations,mapActions } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    // Navbar,
    // AppFooter,
    SoftInput,
    // SoftCheckbox,
    SoftSwitch,
    // SoftButton,
  },
  data() {
    return {
      errors:{},
      data:{},
      loading: false,
      readonly_promo: false,
      
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.approve_promo();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["signup"]),
    approve_promo(){
      if(this.$route.query.promo){
        this.data.promo = this.$route.query.promo;
        this.readonly_promo = true;
      }

    },
     vphone(phone) {
      if(phone.length<8){
        this.errors.phone = true
        return 1
      }
      else{
        this.errors.phone = false
        return 0
      }
     },
     vemail(email) {
      if(email.length<8){
        this.errors.email = true
        return 1
      }
      else{
        this.errors.email = false
        return 0
      }
     },
     vname(name) {
        if(name.length<2){
          this.errors.name = true
          return 1
        }
        else{
          this.errors.name = false
          return 0
        }
     },
     vpassword(pass1,pass2) {
        if(pass1.length<5){
          this.errors.password = true
          return 1
        }
        else{
          this.errors.password = false
        }

        if(pass1 != pass2){
          this.errors.repeat_password = true
          return 1
        }
        else{
          this.errors.repeat_password = false
        }
        return 0

      },
     getFormValues (submitEvent) {
      this.loading = true
      this.data = {
        'email':submitEvent.target.elements.email.value,
        'phone':submitEvent.target.elements.phone.value,
        'promo':submitEvent.target.elements.promo.value,
        'name':submitEvent.target.elements.name.value,
        'password':submitEvent.target.elements.password.value,
        'repeat_password':submitEvent.target.elements.repeat_password.value,
        'utm':this.$route.query
      }
      var error = 0
      error+=this.vphone(this.data.phone)
      error+=this.vemail(this.data.email)
      error+=this.vname(this.data.name)
      error+=this.vpassword(this.data.password,this.data.repeat_password)
      if (error>0){
        this.loading = false
        return 
      }
      this.signup({vm:this,data:this.data})
       .then(response => {
        console.log(response)
         this.loading = false
      })
      .catch(error => {
        console.log(error)
        this.loading = false
      })
      
    }

  },
};
</script>
