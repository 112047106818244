<template>
  <div class="fixed-plugin mb-5" v-if="t_sent">
    <div   class="shadow-lg card my-bg pt-4" style="overflow-y: scroll; overflow-x: hidden !important;">
      <div >
        <h2 class="float-start alex__replenishment-title">{{$t('withdrawn.vivod_sredstv') }}</h2>
        <div class="me-2 pe-3 pt-1 float-end "  >
              <close @click="toggle"/>
        </div>
      </div>
      <div class="p-3 mt-3 text-dark shadow-lg" style="border:1px solid rgb(5, 55, 155); color:rgb(5, 55, 155); border-radius:10px; font-size:16px; opacity:1; ">
        {{$t('withdrawn.withd_request_succ') }}
      </div>
      
      <ranking-list-card
      :horizontal-break="false"
      :card="{
        title: '',
        item:[]
      }"
    >
      <ranking-list
        :item="requests"
        :horizontal-break="false"
      />
    </ranking-list-card>
 
      <div   @click="close_me()" class="cursor-pointer text-light alex-btn-top-up withdraw mb-5" style="border:none;  background: radial-gradient(50% 134.49% at 50% 50%, #557AD3 0%, #05379B 100%);      ">
        {{$t('withdrawn.close') }}
      </div>
    </div>

  </div>
    <div class="fixed-plugin mb-5" v-else>
    <!-- <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed"
      @click="toggle"
    >
      <i class="py-2 fa fa-money"> </i>
    </a> -->
    <div   class="shadow-lg card my-bg pt-4" style="overflow-y: scroll; overflow-x: hidden !important;">
      <div >
        <h2 class="float-start alex__replenishment-title">{{$t('withdrawn.vivod_sredstv') }}</h2>
        <div class="me-2 pe-3 pt-1 float-end "  >
              <close @click="toggle"/>
        </div>
      </div>

      
      

      <div class=" alex__replenishment-description pb-1 ">{{$t('withdrawn.vibor_schet') }}</div>

    <!-- 
      <div class="alex-replenishment__choice mb-0">
          <div  @click="crypto=false; is_withdraw_card = true" class="alex-replenishment__choice-item " :class="[!crypto && is_withdraw_card ? 'alex-choice-item-select': null]">По номеру карты</div>
          <div  @click="crypto=false; is_withdraw_card = false" class="alex-replenishment__choice-item" :class="[!crypto && !is_withdraw_card ? 'alex-choice-item-select': null]">СБП<br>(Номер телефона)</div>
      </div>
      <div class="alex-replenishment__choice mt-1">
          <div @click="crypto=true" class=" alex-replenishment__choice-item " :class="[crypto ? 'alex-choice-item-select': null]">Крипто</div>
      </div> -->
      
      
      <div   class="row alex__replenishment-description">
          <div 
            class='col-6'
            v-for="(item,index) in userBots"
            @click="choose_robot(item, index)"
            :key="item.slug"
          > 
              <div  @click="crypto=false; is_withdraw_card = true" class="mt-2 alex-replenishment__choice-item " 
                :class="[index === check_index ? 'alex-choice-item-select': null]">{{item.name}} <br> {{item.available.toLocaleString('en')}} {{$t('withdrawn.valuta') }}</div>
              </div> 
      </div>

      <div class="attention__block" >
        <div class="attention__block-up-row">
          <img src="../assets/alex-image/attention-logo.svg" alt="">
        </div>
        <div class="pdiv">
          {{ $t('withdrawn.reglament') }}
          <template v-if="robot.hours_to_withdraw>0" >  {{ $t('withdrawn.new_req_soon') }} ~ {{Math.round(robot.hours_to_withdraw+0.5)}} h. .</template>
        </div>
      </div>
     <div class="alex-replenishment_available">
        <div class="alex-input__box">
          <input id="price_out" name="price_out" @change="test()" v-model="payment_data.value" type="number" class="alex-input__withdrawal-amount" :placeholder="$t('withdrawn.sum_withdrow')" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
        </div>
        <div class="alex-replenishment-available-text ms-2" style="opacity: 0.6;">
          <div v-if='calcAvailable>10'>{{$t('withdrawn.dostupno_ot') }} {{from_value}} {{$t('withdrawn.valuta') }} <span v-if="user?.total_available>from_value">{{$t('withdrawn.do') }}  {{calcAvailable}} {{$t('withdrawn.valuta') }} </span></div>
          <div v-else>{{$t('withdrawn.na_etom_schete') }} </div>
        </div>
    </div>

        <div class="alex__replenishment-description mt-5 mb-2">{{$t('withdrawn.metod') }}</div>
      <div class=" mb-0">
          <div @click="crypto=true" class="mt-2 alex-replenishment__choice-item " :class="[crypto ? 'alex-choice-item-select': null]">{{$t('withdrawn.crypto') }}</div>
          <!-- @click="crypto=false; is_withdraw_card = true" -->
          <div   class="mt-2 alex-replenishment__choice-item " style="opacity: 0.4; position: relative;" :class="[!crypto && is_withdraw_card ? 'alex-choice-item-select': null]">{{$t('withdrawn.card') }}

            <div class="ps-2 pe-2" style=" ; top:-5px; right:-5px; position: absolute; background-color: red; color: #fff; border-radius: 10px;">soon</div>
          </div>
          <!-- <div  class="mt-2 alex-replenishment__choice-item" :class="[!crypto && !is_withdraw_card ? 'alex-choice-item-select': null]">{{$t('withdrawn.spb') }}<br>{{$t('withdrawn.spb_add') }}</div> -->
        </div>

    
    
          
        
      <div class="pt-0 ">
      <div class="alex__replenishment-description mt-5 mb-2">{{$t('withdrawn.plata') }} </div>
        
        <div class="" >
          <div v-if="crypto">
            <div class="row ps-3"> 
                <div class="col-3"  
                  @click="payment_data.card=method.address;network=method.slug" 
                  style="border: 0.5px dashed gray; background: #F6F6F8; opacity:0.8;  border-radius: 12px; cursor:pointer; "
                  v-bind:key="method.address" v-for="method in previouseWithdrawMethod.crypto" >
                  <p class="text-dark pt-2 mt-2" style="line-height:1px; font-size: 13px;">Сеть:
                  <strong class="text-dark" style=" font-size: 13px; font-weight:700;"> {{method.slug}}</strong></p>
                  <p class="text-dark" style="line-height:1px; font-size: 10px;"><strong> {{obscureText(method.address)}}</strong></p> 
                </div>
            </div>


            <div class="alex-replenishment_available mt-2 mb-2">
                <div class="alex-input__box">
                  <input id="card" name="card" 
                    @change="payment_data.card=$event.target.value" 
                    v-model="payment_data.card" type="text" 
                    class="alex-input__withdrawal-amount" 
                    :placeholder="$t('withdrawn.numberwallet')"
                    aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
                </div>
            </div>
            
            
              
              <div class="alex-choice__wrapper">
              <div class="alex__replenishment-description ">{{$t('withdrawn.get_crypto') }}</div>
              <div class="alex-choice__wrapper_items">
                  <template v-bind:key="item.slug" v-for="(item) in cryptoAssets" >
                    
                    <div 
                        v-if="asset===item?.slug" 
                        class="alex-choice__wrapper_item alex-choice__item-select" >
                        {{item.name}}
                      </div>
                      <div 
                        v-else @click="asset=item.slug" 
                        class="alex-choice__wrapper_item" >
                        {{item.name}}
                      </div>
                      
                  </template>
                </div>
             </div>
             
              <div class="alex__replenishment-description mb-2">{{$t('withdrawn.network') }}</div>
              

              <div class="alex-choice__wrapper_items">
                <template v-bind:key="item.slug" v-for="(item) in networks" >
                  <div 
                    v-if="network===item?.slug" 
                    class="alex-choice__wrapper_item alex-choice__item-select" 
                    >
                    {{item.name}} 
                  </div>
                  <div 
                    v-else @click="network=item.slug" 
                    class="alex-choice__wrapper_item" 
                    >
                     {{item.name}} 
                  </div>
                </template>
              </div>

              <!-- <soft-input
                id="card"
                type="text"
                @change="payment_data.card=$event.target.value"
                placeholder="Номер кошелька"
                name="card"
              /> -->
              
             
            
          </div>
          <div v-else>
            
            <div class="form-check form-switch ps-0 mt-3" v-if="is_withdraw_card">
            <!-- <label>Сумма</label> -->
            
              <div class="row ps-2"> 
                <div class="col-4"  
                  @click="payment_data.card=method.address;payment_data.fio=method.fio;payment_data.bank=method.bank;bank=method.bank.id" 
                   style="background: #F6F6F8; opacity:0.8;  border-radius: 12px; cursor:pointer; "
                   v-bind:key="method.address" v-for="method in previouseWithdrawMethod.card" >
                   <p class="text-dark pt-2 mt-2" style="line-height:1px; font-size: 13px;">На имя:
                   <strong class="text-dark" style=" font-size: 13px; font-weight:700;"> {{method.fio}}</strong></p>
                   <p class="text-dark" style="line-height:1px; font-size: 10px;">{{method.bank.name}}: <strong> {{obscureText(method.address)}}</strong></p> 
                </div>
              </div>
            
              <div class="alex-replenishment_available mt-2 mb-2">
                <div class="alex-input__box">
                  <input id="card" name="card" 
                  @change="payment_data.card=$event.target.value"
                  v-model="payment_data.card" type="text" 
                  class="alex-input__withdrawal-amount" 
                  :placeholder="$t('withdrawn.number_card')"
                  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
                </div>
              </div>
              
              <div class="alex-replenishment_available mt-2 mb-2">
                <div class="alex-input__box">
                  <input id="price_out" name="price_out" 
                  @change="payment_data.fio=$event.target.value"
                  v-model="payment_data.fio" type="text" 
                  class="alex-input__withdrawal-amount" 
                  :placeholder="$t('withdrawn.name_owmner')"
                  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
                </div>
              </div>
              <div class="row">
                <div class="alex-choice__wrapper_items">
                  <template v-bind:key="item.id" v-for="item in paymentMethods" >
                    <div 
                        v-if="bank===item?.id" 
                        class="alex-choice__wrapper_item alex-choice__item-select" >
                        {{item.name}}
                      </div>
                      <div 
                        v-else @click="bank=item.id;payment_data.bank=item" 
                        class="alex-choice__wrapper_item" >
                        {{item.name}}
                      </div>
                      
                  </template>
                </div>
              </div>
              <div style="display: none;" class="alex-replenishment_available mt-2 mb-2">
                <div class="alex-input__box">
                  <input id="price_out" name="price_out" 
                  v-model="payment_data.bank.name" type="text" 
                  class="alex-input__withdrawal-amount" 
                  :placeholder="$t('withdrawn.nazv_bank')"
                  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
                </div>
              </div>

          </div>
          <div class="form-check form-switch ps-0 mt-3" v-else>
             
              <div class="alex-replenishment_available mt-2 mb-2">
                <div class="alex-input__box">
                  <input id="price_out" name="price_out" 
                  @change="payment_data.phone=$event.target.value"
                  v-model="payment_data.phone" type="text" 
                  class="alex-input__withdrawal-amount" 
                  :placeholder="$t('withdrawn.number')"
                  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
                </div>
              </div>
              <div class="alex-replenishment_available mt-2 mb-2">
                <div class="alex-input__box">
                  <input id="price_out" name="price_out" 
                  @change="payment_data.fio=$event.target.value"
                  v-model="payment_data.fio" type="text" 
                  class="alex-input__withdrawal-amount" 
                  :placeholder="$t('withdrawn.fio')"
                  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
                </div>
              </div>
              <div class="row">
                <div class="alex-choice__wrapper_items">
                  <template v-bind:key="item.id" v-for="item in paymentMethods" >
                    <div 
                        v-if="bank===item?.id" 
                        class="alex-choice__wrapper_item alex-choice__item-select" >
                        {{item.name}}
                      </div>
                      <div 
                        v-else @click="bank=item.id;payment_data.bank=item" 
                        class="alex-choice__wrapper_item" >
                        {{item.name}}
                      </div>
                      
                  </template>
                </div>
              </div>
              <div class="alex-replenishment_available mt-2 mb-2">
                <div class="alex-input__box" style="display: none;">
                  <input id="price_out" name="price_out" 
                  @change="payment_data.bank=$event.target.value"
                  v-model="payment_data.bank.name" type="text" 
                  class="alex-input__withdrawal-amount" 
                  :placeholder="$t('withdrawn.bank')"
                  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
                </div>
              </div>
          </div>
          </div>
        </div>
        <hr class="horizontal dark my-sm-4" /> 
        <div  v-if="user.green_sign" class="alex-replenishment-available-text ms-1 mb-3" style="color: #159600; opacity: 0.9;">
          <div>
          <svg class="mb-1" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3499 8.00002C15.3499 3.94073 12.0592 0.650024 7.9999 0.650024C3.94061 0.650024 0.649902 3.94073 0.649902 8.00002C0.649902 12.0593 3.94061 15.35 7.9999 15.35C12.0592 15.35 15.3499 12.0593 15.3499 8.00002Z" stroke="#159600" stroke-width="1.2" stroke-miterlimit="10"/>
            <path d="M10.7032 6.23223L7.16767 9.76777L5.3999 8" stroke="#159600" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            У вас зеленая галочка, <br>комиссия за ввод и вывод (кроме TRC) – 0%
          </div>
        </div>
        <div class="alex-replenishment-available-text ms-1 mb-3" style="opacity: 0.6;">
          <template v-if="commission>-0.1">
            {{$t('withdrawn.commission') }}
              
           {{commission}} {{$t('withdrawn.valuta') }}
          </template>
          <template v-else>{{$t('withdrawn.sum') }}</template>
        </div>
    
        <div v-if="allow>0"  @click="withdraw();" class="cursor-pointer alex-btn-top-up withdraw mb-5">
          {{$t('withdrawn.vivesti') }}
        </div>
        <template  v-else >
          
          <span v-if="allow==-1" class="text-danger" style='opacity:0.5'>Проверьте данные вывода, возможно сумма + комиссия превышают доступный баланс</span>
          <div style="opacity:0.3; cursor:not-allowed" class="mt-4 alex-btn-top-up withdraw mb-5">
            {{$t('withdrawn.vivesti') }}
          </div>
        </template>
       




      </div>
    </div>
  </div>
</template>



<style scoped>


.my-bg{
  background-color: #FEFEFE !important;
}
.alex-header {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: 'RFDewi-Semibold';
  font-size: 16px;
  padding: 22px 18px 22px 16px;
}

.alex-header svg {
  rotate: 180deg;
  position: absolute;
  left: 16px;
}

.alex-color__block {
  padding: 22px 29px 22px 30px;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  width: 100%;
  align-items: center;
  background: linear-gradient(270deg, #FF2749 -6.64%, #6039FB 141.62%);
}

.alex-color__block-label {
  color: #FFF;
  font-size: 20px;
  font-family: 'RFDewi-Regular';
  line-height: normal;
  letter-spacing: 0.2px;
}

.alex-color__block-value {
  color: rgba(255, 255, 255, 0.50);
  font-size: 30px;
  font-family: 'RFDewi-Semibold';
  line-height: normal;
  letter-spacing: 0.3px;
}

.alex-color__block-value span {
  font-family: 'RFDewi-Bold';
  color: #FFF;
}

.alex-office__container {
  margin-top: 35px;
  padding: 0 17px 0 16px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.alex-office__block {
  display: flex;
  gap: 16px;
}

.alex-office__block_content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 4px 0 0;
}

.alex-office__container-title {
  color: #151515;
  font-family: 'RFDewi-Bold';
  font-size: 24px;
  letter-spacing: -.3px;
}

.alex-office__block_content-title {
  color: #070707;
  font-family: 'RFDewi-Bold';
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  text-transform: uppercase;
}

.alex-office__block_content-text {
  color: #191919;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}

.alex-office__info_blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 10px;
}

.alex-office__info_blocks-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 15px;
  background: #242121;
  padding: 10px 20px;
  flex: 1;
  white-space: nowrap;
}

.alex-office__info_blocks-item:first-child {
  width: 100%;
  flex-basis: auto;
  align-items: center;
}

.alex-office__info_blocks-item-title {
  color: #D8E3FC;
  font-size: 18px;
}

.alex-office__info_blocks-item-value {
  color: #FFF;
  font-size: 26px;
  font-family: 'RFDewi-Bold';
}

.alex-office__info {
  display: flex;
  flex-direction: column;
  padding: 30px 18px;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #BABDC5;
  background: #F6F6F8;
}

.alex-office__info-description {
  color: #373737;
  font-size: 18px;
}

.alex-office__info-title {
  color: #1B1B1B;
  font-size: 22px;
  font-family: 'RFDewi-Semibold';
  margin-top: 10px;
  margin-bottom: 15px;
}

.alex-office__info_item {
  display: flex;
  padding: 10px;
  gap: 15px;
  border-radius: 9px;
  background: #ECEEF3;
}

.alex-office__info_item-text {
  color: #373737;
  font-size: 16px;
}

.alex__replenishment {
  display: flex;
  justify-content: space-between;
  font-family: 'RFDewi-Semibold';
}

.alex__replenishment-text {
  color: #000;
  font-size: 18px;
  max-width: 220px;
}

.alex__replenishment-amount {
  font-size: 24px;
}

.alex__replenishment-amount span {
  font-family: 'RFDewi-Bold';
}

.alex-btn-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.alex-btn-top-up {
  display: flex;
  padding: 24px 108.5px 23px 108.5px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #000;
  color: #000;
  font-family: 'RFDewi-Semibold';
  font-size: 26px;
  background: transparent;
}

.alex-btn-see-results {
  display: flex;
  border-radius: 16px;
  border: 1px solid #BABDC5;
  background: #ECEEF3;
  padding: 17px 55px;
  align-items: center;
  color: #3A3A3A;
  font-family: 'RFDewi-Semibold';
  text-align: center;
  justify-content: center;
  font-size: 18px;
}

.alex-block-separator {
  width: 287px;
  height: 2px;
  background: rgba(0, 0, 0, 0.51);
  margin: 49px auto;
  border-radius: 1px;
}

/* replenishment */
.alex-replenishment {
  padding-top: 110px;
  padding-bottom: 70px;
  display: flex;
  position: relative;
  padding-left: 20px;
  max-width: 640px;
  margin: 0 auto;
}

.alex-replenishment-col {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.18);
  min-width: 20px;
  height: 100%;
}

.alex-replenishment-container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.alex__replenishment-title {
  color: #000;
  font-size: 26px;
  letter-spacing: 0.26px;
  font-family: 'RFDewi-Semibold';
}

.alex__replenishment-description {
  color: rgba(0,0,0,0.6);
  font-size: 18px;
  letter-spacing: 0.18px;
}

.alex__replenishment_btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.alex__replenishment_btns-btn {
  display: flex;
  gap: 10px;
  color: #fff;
  font-family: 'RFDewi-Bold';
  padding: 15px 10px;
  border-radius: 7px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  height: max-content;
}

.alex-btn-black {
  background: #141414;
}

.alex-btn-blue {
  background: linear-gradient(97deg, #1750D1 2.83%, #27E2FC 108.6%);
  line-height: 15px;
}

.alex-btn-pink {
  background: linear-gradient(99deg, #8020D3 16.22%, #F75593 118.6%);
}

.alex-replenishment_available {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.alex-input__box {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #c3c3c3;
  background: #FFF;
  padding: 10px 10px 10px 1px;
}

.alex-input__box p {
  color: #000;
  font-size: 24px;
}

.alex-input__box input {
  width: 100%;
  padding-left: 30px;
  font-size: 20px;
  letter-spacing: 0.2px;
  margin-top: 4px;
  border:none;
}


.alex-input__box input::placeholder {
  color: #8f8f8f !important;
}
input:focus{
  border:none !important;
  box-shadow:none !important;
  border-color:none !important;
}

.alex-input__box input::-webkit-outer-spin-button,
.alex-input__box input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.alex-input__box input[type='number'] {
  -moz-appearance: textfield !important;
}

.alex-replenishment-available-text {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 14px;
  font-style: italic;
  line-height: normal;
}

.alex-replenishment__choice {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 18px;
  background: #F6F6F8;
  padding: 8px 7px;
}

.alex-replenishment__choice-item {
  display: flex;
  color: #3A3A3A;
  padding: 20px 0;
  border:1px solid rgba(0,0,0,0.1);
  font-size: 14px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-family: 'RFDewi-Semibold';
  letter-spacing: 0.14px;
  flex: 1;
  padding: 20px 10px;
  min-width: 140px;
  height: 56px;
}

.alex-choice-item-select {
  color: #FFF;
  background: #000;
}

.alex-replenishment__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 35px;
}

.alex-replenishment__cards-item {
  width: 79px;
  height: 45px;
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  border: 1px solid #BABDC5;
  background: #F6F6F8;
  padding: 4px 3px;
  gap: 3px;
}

.alex-replenishment__cards-item img {
  width: 28px;
  height: 17px;
  object-fit: cover;
}

.alex-replenishment__cards-item p {
  color: #333;
  font-size: 12px;
  letter-spacing: 0.12px;
  width: 100%;
  text-align: right;
}

.alex-replenishment__cards-add {
  width: 79px;
  height: 45px;
  display: flex;
  border-radius: 11px;
  background: #F6F6F8;
  color: #161616;
  font-size: 32px;
  justify-content: center;
  align-items: center;
}

.alex-replenishment__cards .alex-input__box {
  margin-top: 10px;
}

.alex-replenishment__cards input {
  padding-left: 0;
}

.alex-checkbox-transform input {
  display: none;
}

.alex-checkbox__label:before {
  content: ' ';
  display: block;
  height: 23px;
  width: 23px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  background: transparent;
}

.alex-checkbox__label:after {
  content: '';
  display: block;
  height: 23px;
  width: 23px;
  border-radius: 4px;
  transition: 200ms;
  position: absolute;
  top: 0px;
  left: 0px;
  /* background: #fff200; */
  transition: 100ms ease-in-out;
}

.alex-checkbox__input:checked~.alex-checkbox__label:after {
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 11px;
  width: 15px;
}



.alex-checkbox__label {
  color: #202020;
  font-size: 16px;
  letter-spacing: 0.16px;
  opacity: 0.5;
  margin-left: 10px;
}

.alex-replenishment__user-data {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.alex-replenishment__user-data input {
  padding-left: 0;
}

.alex-replenishment__commission {
  display: flex;
  justify-content: space-between;
  color: #202020;
  font-size: 16px;
  letter-spacing: 0.16px;
}

/* replenishment END */
/* replenishment */
.alex-choice__wrapper {
  margin-top: 30px;
}

.alex-choice__wrapper-label {
  color: #AAA;
  font-size: 18px;
  letter-spacing: 0.18px;
}

.alex-choice__wrapper_items {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.alex-choice__wrapper_item {
  color: #000;
  font-family: 'RFDewi-Semibold';
  font-size: 18px;
  border-radius: 10px;
  background: #F6F6F8;
  padding: 17px 17px;
}

.alex-choice__item-select {
  color: #FFF;
  background: #242121;
}

.alex-promocode {
  margin: 35px 0;

}

.alex-promocode .alex-checkbox__label {
  font-style: italic;
}

.alex-choice__money_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 7px;
}

.alex-choice__money_item {
  padding: 7px 5px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #D3D4DA;
  background: #FFF;
  color: #000;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 0.18px;
  flex: 1;
  white-space: nowrap;
}

.alex-choice__money_item:hover {
  color: #FFF;
  background: #242121;
  border: 1px solid transparent;
}

.alex-choice__money_wrapper .alex-input__box p {
  font-size: 20px;
}

.alex-choice__money_wrapper input {
  padding-left: 5px;
  margin-top: 0;
}

.attention__block {
  margin-top: 35px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
}

.attention__block-up-row {
  background: #FAB23C;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attention__block .pdiv {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background: #FFFEF5;
  color: #533D05;
  font-size: 12px;
  padding: 10px 20px 15px;
  letter-spacing: 0.12px;
  text-align: center;
}

.attention__block p span {
  font-weight: 700;
}

.alex-qr-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
}

.alex-qr-code_btn-copy {
  margin-top: 30px;
  width: 156px;
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 32px;
  background: #0D0D0D;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  align-items: center;
  font-family: 'FactorA-Regular-narrow', sans-serif;
  font-weight: 500;
}

.alex-qr-code p {
  margin-top: 15px;
  color: #4F4F4F;
  font-family: 'FactorA-Regular-narrow', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20.333px;
  letter-spacing: 0.36px;
}

.alex-replenishment .alex-block-separator {
  width: 100%;
  margin: 25px 0;
}

.alex-replenishment-footnote {
  font-family: 'FactorA-Regular-narrow', sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.12px;
}

/* replenishment END  */
@media screen and (max-width: 360px) {
  .alex-office__info_blocks-item {
     align-items: center;
  }
}

</style>

<script>
// import SoftRadio from "@/components/SoftRadio.vue";
// import SoftAlert from "@/components/SoftAlert.vue";
// import SoftInput from "@/components/SoftInput.vue";
import RankingList from "@/examples/Cards/RankingList.vue";
import RankingListCard from "@/examples/Cards/RankingListCard.vue";

import { mapGetters,mapMutations, mapActions } from "vuex";
import Close from "../components/Icon/Close.vue";
export default {
  name: "withdraw",
  props: ["toggle"],
  data() {
    return {
      check_index:0,
      network: '',
      asset:'usdt',
      bank:1,
      crypto:true,
      t_sent:false,
      is_withdraw_card:true,
      payment_data: {
        phone: '',
        bank: '',
        fio: '',
        card: '',
        bot_slug: 'alpha'

        },
        req: {
        in:{
          icon: 'fa-arrow-down',
          color: 'info',
          sub: 'Deposit'
        },
        out:{
          icon: 'fa-arrow-up',
          color: 'info',
          sub: 'Withdraw Request'
        },
        reinvest:{
          icon: 'fa-arrow-up',
          color: 'dark',
          sub: 'Reinvest'
        }
      },
      fixedKey: "",
      button: true,
      robot:{},
      
    };
  },
  components: {
    // SoftRadio,
    // SoftAlert,
    Close,
    RankingList,
    RankingListCard,
    // SoftInput
  },
  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor","newWithdraw", "getTransactions","getCryptoAssets","GetPaymentMethods","GetPreviouseWithdrawMethod"]),

    sidebarColor(color = "warning") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },
     obscureText(text) {
    // Проверяем, что длина строки больше 4 символов
      if (text.length <= 4) {
        return text; // Если строка слишком короткая, возвращаем ее без изменений
      }

      // Заменяем текст между первыми двумя и последними двумя символами на три точки
      const obscuredText = text.replace(/(?<=^...).*(?=....$)/, '...');

      return obscuredText;
    },

    sidebarType(type) {
      this.toggleSidebarColor(type);
    },
    setNullData() {
      this.payment_data = {
        phone: '',
        bank: {},
        fio: '',
        card: '',
        bot_slug: 'alpha'

        }
    },

    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },
    close_me(){
      this.t_sent = false;
      this.toggle()

    },
    withdraw(){
      
      if (this.from_value>this.payment_data.value && this.is_withdraw_card){
          this.$toast.error(this.$t('withdrawn.check_sum'), { });
        return 
      }
      else if (this.from_value>this.payment_data.value && !this.is_withdraw_card){
        this.$toast.error(this.$t('withdrawn.check_sum'), { });
        return 
      }
      else if(this.payment_data.value>this.calcAvailable) {
          this.button = true
          this.payment_data.value = this.calcAvailable
          this.$toast.error(this.$t('withdrawn.check_sum'), { });

        }
       
      var data = this.payment_data
      data['bot_slug'] = this.robot.slug
      if (this.crypto){
        data['is_withdraw_card'] = true
        data['asset'] = this.asset
        data['network'] = this.network

        if (this.payment_data.card.length<10) {
          this.payment_data.card = ''
          alert(this.$t('withdrawn.check_wallet'))
          return 
        }
      
      }
      else{
        data['is_withdraw_card'] = this.is_withdraw_card
      }


      if (this.is_withdraw_card && !this.crypto){
        if (!this.payment_data.bank.id) {
            alert('Bank Issue')
            return 
          }
      }

      if (this.is_withdraw_card && !this.crypto){
        if (this.payment_data.card.length<5) {
          alert(this.$t('withdrawn.check_number'))
          return 
        }
      }

      else {
        
        if (this.payment_data.phone.length<5 && !this.crypto) {
          alert(this.$t('withdrawn.check_phone'))
          return 
        }
        if (!this.payment_data.bank.id && !this.crypto) {
            alert('Bank Issue')
          return 
        }
      }

      if (!this.crypto){
        if (this.payment_data.fio.length<5) {
          alert(this.$t('withdrawn.check_dannie'))
          return 
        }
      }
     
      data['comission'] = this.commission

      this.newWithdraw({vm:this,data:data}).then(status => {
        this.t_sent = true
        setTimeout(() => {
          console.log(status)
          
          this.getTransactions()
        }, 200);
      })
    },
   
    choose_robot(item, index){
      console.log(index)
      this.check_index = index
      this.robot = item
    },
    test(){

         if(this.payment_data.value>30000 && !this.crypto && this.calcAvailable>30000) {
          alert(this.$t('withdrawn.warning1'))
          alert('Attention! To avoid bank blocks (as per Federal Law 115), the recommended withdrawal amount should not exceed 50,000 rubles per day. Please split the transaction into smaller amounts, use cryptocurrency, or contact your account manager.')
         }
         if(this.payment_data.value>this.calcAvailable) {
          this.button = true
          this.payment_data.value = this.calcAvailable - this.commission
          this.$toast.info(this.$t('withdrawn.info_sum'), { });
        }
        else if (10>this.payment_data.value){
          this.button = false
          this.payment_data.value = 10
          this.$toast.info(this.$t('withdrawn.not_max'), { });

        }
        else{
          this.button = false
        }

         
    }
  },
  computed: {
    ...mapGetters(["user","userBots","cryptoAssets","userRequests","paymentMethods","previouseWithdrawMethod"]),
    from_value(){
      if (this.crypto){
        if(this.network==='eth'){
          return 10
        }
        else if(this.network==='trc20'){
          return 10
        }
        else if(this.network==='bep20'){
          return 10
        }
        else {
          return  10
        }
      }
      else{
        return 10
      }

    },
    cards(){
      return {
            title: 'Транзации',
            item:this.transactions
          }
     },
     requests(){
      var tt = []
      this.userRequests.forEach(element => {
          var t_amount = '-'
          if (element.value){
            t_amount = element.value.toLocaleString('en')
          }

          tt.push({
            title: element.portfolio.name,
            date: `${this.req[element.in_out].sub} от ${element.date}`,
            amount: `${t_amount} $`,
            icon:  this.req[element.in_out].icon,
            color: this.req[element.in_out].color,
        })
      });
      return tt

     },

    networks() {
      var result = []
      
      this.cryptoAssets.forEach(item => {

        if (item.slug === this.asset){
          console.log('slugs',item.slug,item)
          result = item.networks
        }
      })
      
      return result
    },
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    checked(index){
      return index==this.check_index

    },
    // is_withdraw_card(){
    //   if(this.payment_data.value>0) {
    //       return  true
    //      }
    //      else{
    //       return false
    //      }
    // },
    commission_text(){
      if (!this.user.commisions_constants){
        return '0%'
      }
      if (this.crypto){
        return `${this.user.commisions_constants.crypto}%`
      }
    
      if ( this.is_withdraw_card){
        return `${this.user.commisions_constants.card}%`
      }
      else {
        return `${this.user.commisions_constants.sbp}%`
      }
    },
    commission(){
      if (!this.user.commisions_constants){
        return 0
      }
      if (this.crypto){
        if(this.network==='bep20'){
          return this.user.commisions_constants.crypto_bep20
        }
        else if(this.network==='trc20'){
          return this.user.commisions_constants.crypto_trc20
        }
        else if(this.network==='eth'){
          return this.user.commisions_constants.crypto_erc20
        }
        else {
          return this.user.commisions_constants.crypto
        }
      }
    
      if ( this.is_withdraw_card){
        return Math.round(this.payment_data.value * this.user.commisions_constants.card)/100
      }
      else {
        return Math.round(this.payment_data.value * this.user.commisions_constants.sbp)/100
      }
    },
    allow(){
      if (this.payment_data.value + this.commission > this.calcAvailable){
        return -1
      }
      return 1
    },
    calcAvailable(){
      if ('available' in this.robot) return this.robot.available
      else {
        if (this.userBots.length > 0 )
          return this.userBots[0].available
        else 
          return 0
    }
    },

    
  },
  watch:{
 

  },
  beforeMount() {
    this.getCryptoAssets()
    this.GetPaymentMethods()
    this.GetPreviouseWithdrawMethod()
    this.$store.state.isTransparent = "bg-dark";
    // Deactivate sidenav type buttons on resize and small screens
  },
};
</script>
