<template>
  <div class="fixed-plugin" >
    <!-- <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed"
      @click="toggle"
    >
      <i class="py-2 fa fa-money"> </i>
    </a> -->
    <div class="shadow-lg card blur" style="overflow-y: scroll; overflow-x: hidden !important;">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">
            
            {{$t('reinvestirovat.reinvest_title') }} 
            </h5>
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <close />
        </div>
        <!-- End Toggle Button -->
      </div>
      <div class="pt-0  ">
        <div class="attention__block mb-3" >
          <div class="attention__block-up-row">
            <img src="../assets/alex-image/attention-logo.svg" alt="">
          </div>

          <div class="pdiv">
            {{$t('reinvestirovat.warning') }}
          </div>
        </div>
        <!-- Sidebar Backgrounds -->
        <div class="">
          <h6 class="mb-0">{{$t('reinvestirovat.with_pribil') }} </h6>
          <p class="text-sm"></p>
        </div>

        <div class="row"> 
          <div class="col-6 pt-3 col-md-3"  v-for="item in userBots"  v-bind:key="item.id">
            <div 
              class="d-flex  cursor-pointer align-items-center justify-content-center  p-1" 
              @click="robot_from_slug = item.slug"
              style="flex-direction:column; gap:8px; background-color: #efefef; border-radius: 15px; height:130px; text-align: center; font-size: 12px; line-height: 13px;"
              :class="robot_from_slug === item.slug ? 'select-choose text-dark ' : ''"
              >
              <span style="font-weight: 600; width: 70%;">
                {{item.name}}
              </span>
                <br>
              <span class="" style="font-size: 13px;">
                 {{$t('reinvestirovat.dostupno') }} <br> <strong> {{item.available.toLocaleString('en')}} {{$t('reinvestirovat.valuta') }} </strong>
              </span>
            </div>
          </div>

        </div>
         <!-- <select disabled v-model="robot_from_slug" class="form-select" aria-label="Default select example" @change="switchSelect();">
          <template v-for="item in userBots"  v-bind:key="item.id">
            <option 
               :value="item.slug"
               selected
              >{{item.name}} ({{$t('reinvestirovat.dostupno') }} {{item.available.toLocaleString('en')}} {{$t('reinvestirovat.valuta') }} )</option>
          </template>
          </select> -->
         

         <div class="mt-6">
          <h6 class="mb-0">{{$t('reinvestirovat.na_deposit') }}</h6>
          <p class="text-sm"></p>
        </div>

        <div class="row"> 
          <div class=" col-6 pt-3 col-md-3"  v-for="item in userBots"  v-bind:key="item.id">
            <div 
              class="d-flex  cursor-pointer align-items-center justify-content-center  p-1" 
              @click="robot_to_slug = item.slug"
              style="flex-direction:column; gap:8px; background-color: #efefef; border-radius: 15px; height:70px; text-align: center; font-size: 12px; line-height: 13px;"
              :class="robot_to_slug === item.slug ? 'select-choose text-dark' : ''"
              >
              <span style="font-weight: 600; width: 70%;">
                {{item.name}}
              </span>
            </div>
          </div>

        </div>

<!--         
        <select  v-model="robot_to_slug" class="form-select" aria-label="Default select example" @change="switchSelect()">
            <option 
              v-for="(item, index) in userBots"  
              v-bind:key="item.id" 
              v-bind:selected="index === 0"
               :value="item.slug"
              >{{item.name}} </option>
          </select> -->
        <!-- , {{$t('reinvestirovat.deposit') }} <span v-if='item.deposits' >{{item.deposits.toLocaleString('en')}} {{$t('reinvestirovat.valuta') }}</span> <span v-else> 0 {{$t('reinvestirovat.valuta') }} </span> -->
        <!-- <div class="">
          <template v-for="(item, num) in userBots"  v-bind:key="item.id">
            <soft-radio
              :id="item.id"
              name="robot_to"
              :checked="num === 0 ? true : false"
              v-model="robot"
              v-on:change="robot = item"
              >{{item.name}} <span v-if='item.deposits' class="badge badge-sm rounded-pill w-content bg-dark">{{item.deposits.toLocaleString('en')}} </span> 
            </soft-radio >
          </template>
          
        </div> -->
     
        <!-- Navbar Fixed -->
          
        <div class="mt-3">
          <h6 class="mb-0">{{$t('reinvestirovat.sum') }}</h6>
          <p class="text-sm"></p>
        </div>
        <div class="input-group mb-3" >
          <input 
            id="price"
            type="number"
            v-model='price'
            placeholder="Amount reinvest"
            name="price"
            class="form-control" 
            aria-label="Dollar amount (with dot and two decimal places)">
          <span class="input-group-text">{{$t('reinvestirovat.valuta') }}</span>
        </div>
        <!-- <soft-alert
            color = 'light'
            v-if='robot_to'
            ><i
                class="fa fa-chart-pie opacity-6 me-1"
                aria-hidden="true"
                style='color:#000'
              ></i>
            <small style='color:#000'><b> {{robot_to.name}}</b>
            {{robot_to.revenue}} </small> <br>
            <small style='color:#000'> Реинвестирование возможно при сумме депозита
           от ${{robot_to.minimum.toLocaleString('en')}} до  ${{robot_to.maximum.toLocaleString('en')}}  </small> <br>
           
            
          </soft-alert > -->
        <!-- <div class="form-check form-switch ps-0">
            <soft-input
              id="price"
              :value='price'
              @change="price=$event.target.value"
              v-model='price'
              type="number"
              placeholder="Сумма к пополнению"
              name="price"
            />
        </div> -->
        <hr class="horizontal dark my-sm-4" />

        <div
          class="btn-black  bg-gradient-dark w-100"
          @click="reinvest"
          >{{$t('reinvestirovat.reinvest') }}
          </div >
        
        <!-- <soft-alert color = 'info' > -->
             <!-- <small>
              - реинвестировать можно 1 раз в НЕДЕЛЮ (не больше) <br>
              - заявка рассматривается 24 часа <br>
              - при одобрении, датой старта начисления является дата отправленной вами заявки (если долго будем проверять, проценты все равно пересчитаются)
            </small> -->
            
          <!-- </soft-alert > -->

       

      </div>
    </div>
  </div>
</template>
<style>
.attention__block {
  margin-top: 35px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  
}

.select-choose{
  border: 2px solid #FAB23C;
  background: #d8d8d8 !important;

}
.attention__block-up-row {
  background: #FAB23C;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attention__block .pdiv {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background: #FFFEF5;
  color: #533D05;
  font-size: 12px;
  padding: 10px 20px 15px;
  letter-spacing: 0.12px;
  text-align: center;
}

.attention__block p span {
  font-weight: 700;
}
.btn-black {
  background: #141414;
  width: 100%;
  border-radius: 15px;
  height: 50px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
</style>
<script>
// import SoftRadio from "@/components/SoftRadio.vue";
// import SoftAlert from "@/components/SoftAlert.vue";
// import SoftInput from "@/components/SoftInput.vue";
import { mapMutations, mapActions,mapGetters } from "vuex";
import Close from "../components/Icon/Close.vue";

export default {
  name: "configurator",
  props: ["toggle"],
  data() {
    return {
      fixedKey: "",
      robots: [
        {name: "Бонсай",key:"alpha", revenue: "от 0.88% до 0.98%", minLimit:1,maxLimit:499,checked:true},
        ],
      robot_from_slug: 'alpha',
      robot_to_slug: 'alpha',
      price: 0,
      robot_to:{name: "Бонсай",key:"alpha", revenue: "от 0.88% до 0.98%", minimum:1,maximum:499,init:true, },
      robot_from: {name: "Бонсай",key:"alpha", revenue: "от 0.88% до 0.98%", minimum:1,maximum:499,init:true},
    };
  },
  components: {
    // SoftRadio,
    // SoftAlert,
    // SoftInput
    Close
  },

  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor","getUserData","NewReinvest", "getTransactions"]),

    sidebarColor(color = "warning") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },
    reinvest(){
      console.log('from_robot',this.robot_from)
      console.log('to_robot',this.robot_from)
      console.log('proce',this.price)
      var data = {'from': this.robot_from_slug , 'to': this.robot_to_slug, 'value':this.price }
      this.NewReinvest({vm:this,data:data})
      .then(status => {
        console.log(status)
        // time out run function
        
        this.getTransactions()
        this.toggle()
      })
    },
    sidebarType(type) {
      this.toggleSidebarColor(type);
    },
  
    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },
    
    switchSelect() {
      this.userBots.forEach(element => {
        if (this.robot_from_slug === element.slug) this.robot_from = element
        if (this.robot_to_slug === element.slug) this.robot_to = element
      })
    },
  },

  mounted() {
    this.switchSelect()
    // set timout after 5 sevond run 
  },
  computed: {
     ...mapGetters(["user","userBots"]),
     min(){
      return 1
     },
     robot(){
      return this.userBots[0]
     },
     max(){
      return 100
     },
    ifTransparent() {
      return this.$store.state.isTransparent;
    },


  },
  watch:{
    price(val){
      this.switchSelect()
      if (val > this.robot_from.available)  {
         this.price = this.robot_from.available
        this.$toast.info(this.$t('reinvestirovat.sum_exp'), { });
      }
     
    }
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-dark";
    // Deactivate sidenav type buttons on resize and small screens
    this.sidebarColor('warning')
  },
};
</script>
