import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Invest from "@/views/Invest.vue";
import Billing from "@/views/Billing.vue";
import Withdraw from "@/views/Withdraw.vue";
import Profile from "@/views/Profile.vue";
// import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
// import SignUpRedirect from "@/views/SignUpRedirect.vue";
import Calc from "@/views/Calc.vue";
import Restore from "@/views/Restore.vue";
import History  from "@/views/History.vue";
import Automatic  from "@/views/Automatic.vue";
import TopUp  from "@/views/TopUp.vue";
import InApp  from "@/views/InApp.vue";
import Tasks  from "@/views/Tasks.vue";


const withPrefix = (prefix, routes) => 
    routes.map( (route) => {
        route.path = prefix + route.path;
        return route;
    });


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/pay-on",
    name: "TopUp",
    component: TopUp,
  },
  {
    path: "/invest",
    name: "Invest",
    component: Invest,
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: Tasks,
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: Withdraw,
  },

  {
    path: "/reports-sign",
    name: "Reports",
    component: Billing,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/automatic",
    name: "Automatic",
    component: Automatic,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  // {
  //   path: "/sign-up-redirect",
  //   name: "Sign Up",
  //   component: SignUpRedirect,
  // },

  {
    path: "/sign-calculator",
    name: "Calc",
    component: Calc,
  },
  {
    path: "/sign-restore",
    name: "Restore",
    component: Restore,
  },

  ...withPrefix('/inapp',[
        { 
            path: '/investments',
            component: InApp,
            props:{
                pageTitle: 'investments',
                text: 'investments'
            },
        },
        { 
            path: '/withdraw',
            component: InApp,
            props:{
                pageTitle: 'withdraw ',
                text: 'withdraw'
              },
        },
        { 
          path: '/invest',
          component: InApp,
          props:{
              pageTitle: 'invest',
              text: 'invest'
          },
        },
        { 
          path: '/finance',
          component: InApp,
          props:{
              pageTitle: 'finance',
              text: 'finance'
          },
        },
        { 
          path: '/instruction',
          component: InApp,
          props:{
              pageTitle: 'instruction',
              text: 'instruction'
          },
        },
        { 
          path: '/tasks',
          component: InApp,
          props:{
              pageTitle: 'tasks',
              text: 'tasks'
          },
        },
        { 
          path: '/profile',
          component: InApp,
          props:{
              pageTitle: 'Profile',
              text: 'profile'
          },
        },{ 
          path: '/statref',
          component: InApp,
          props:{
              pageTitle: 'Stat',
              text: 'statref'
          },
        },{ 
          path: '/hong-kong',
          component: InApp,
          props:{
              pageTitle: 'HongKong',
              text: 'hong-kong'
          },
        },{ 
          path: '/how-it-works',
          component: InApp,
          props:{
              pageTitle:'How it works',
              text: 'how-it-works'
          },
        },{ 
          path: '/gotochatfromtask',
          component: InApp,
          props:{
              pageTitle:'gotochatfromtask',
              text: 'gotochatfromtask'
          },
        }, { 
          path: '/technical-support',
          component: InApp,
          props:{
              pageTitle:'Technical support',
              text: 'technical-support'
          },
        },{ 
          path: '/top-up',
          component: InApp,
          props:{
              pageTitle:'Top  uo',
              text: 'top-up'
          },

        },{ 
          path: '/order-in-process',
          component: InApp,
          props:{
              pageTitle:'order-in-process',
              text: 'order-in-process'
          },
        },
    ]),


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
