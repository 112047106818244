<template>
  <div class="" style="">
   <div class="row">
    
      <div class="row m-1" style="" v-if="userBots.length>0">
        <div class=" col-12 mt-4" 
          v-for="robot in userBots" v-bind:key="robot.id"
          :class="robot.slug=='union' ? 'col-md-8' : 'col-md-4'"
          >
          <div class="pt-2 line-robot bg-dark " style="position: relative;  " >
                  <svg class="w-100 h-100 mt-n5" style="position: absolute; opacity: 0.1;" width="250" height="160" viewBox="0 0 250 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                   
                    </svg>





            <div class="ps-3 mt-3" style="">
              <div class="ps-1 text-uppercase factor" style=" color:#fff; font-size:25px;  font-weight:500"> {{robot.name}}</div>
              <div class="mt-1 p-1 ps-2 pe-2"  style="font-weight:400; color:#fff; line-height:16px; border-radius: 10px; border: 1px solid rgb(186, 189, 197); width: fit-content;">
                {{$t('withdrawn.account_balance') }}: 
                <strong style="color: #FAB23C;">{{robot.deposits.toLocaleString('en') }} {{$t('withdrawn.valuta') }} </strong></div>  <br>
            </div>
            <!-- <div  style="border-top: 1px solid #BABDC5;"></div> -->

            <div class="p-3">
              <div style="color:#fff">{{$t('withdrawn.dostupno') }}</div>
              
              <div  v-if="robot.available" class="d-flex  align-items-center justify-content-between" >
                <div class="" style="font-size:30px; font-weight: 900;color:#fff;">+ {{robot.available.toLocaleString('en')}} {{$t('withdrawn.valuta') }}  </div>
              </div> 

              <h2 v-else class=" " style="font-size:30px; font-weight: 900; color:#fff;"> 0 {{$t('withdrawn.valuta') }}</h2>
              

              <div v-if="robot.available_processing>5" class="text-warning"><div class="spinner-grow spinner-grow-sm me-2" role="status">
                <span class="visually-hidden">{{$t('withdrawn.loading') }}</span>
              </div> {{$t('withdrawn.obrabotka') }} {{robot.valuta}}  </div>
            </div>
            <button type="button"  v-if="robot.available" @click="withdraw(robot.id)"  class="btnmy-withdraw w-100 mybtn" style="position: absolute; bottom: 0px; right:0px">{{$t('withdrawn.vivesti') }}</button>
            <button type="button"  v-else @click="walert('Low funds')" class="mybtn btnmy-withdraw w-100" style="position: absolute; bottom: 0px; right:0px">{{$t('withdrawn.vivesti') }}</button>
        </div>
      </div>
      </div>
      <div class="ps-4 pb-4" v-else>
        <div class="shadow-lg p-4 line-robot" style="position: relative; width:80%;display: inline-block;vertical-align: middle;  margin-left: 4px;  border-radius: 30px; height:150px">
          <h3 class="mb-0 text-uppercase text-dark"> {{$t('withdrawn.download') }} </h3>
          <div class="small mb-2 badge  bg-light text-dark"  style="">{{$t('withdrawn.poisk_schetov') }}</div>  <br>
          <h2 class=" font-weight-bold mb-0">  </h2>
        </div>
      </div>
   </div>

  <div class=" m-3 pb-0 " v-if="user?.profit_images?.length>0">
    <h2 class="mb-0 pb-0 pb-3 text-dark" style="">  {{$t('withdrawn.last_profit') }} </h2>
    
    <div class="pb-4" style="overflow-x: scroll; width:100%; white-space: nowrap; ">

      <template v-for="(imgp,index) in user?.profit_images" v-bind:key="index">
        <img class="hover-big" style="border-radius:10px; margin-right:10px; height: 130px;" :src="imgp" alt="">
      </template>

    </div>
    <router-link
                  :to="{ name: 'History' }"
                  class="text-dark font-weight-bolder"
                >
                <span style="font-size:18px; text-decoration:underline; cursor:pointer;">   {{$t('withdrawn.all_history') }} </span>
    </router-link>

    
  </div>
  <div  class="m-4" style="border-top: 1px solid #BABDC5;"></div>

  <div class=" m-3 pb-0 ">
    <h3 class="mb-0 pb-0 text-dark" style="">{{$t('withdrawn.zayavki') }}</h3>
    <p  v-if='requests.length==0' class="mt-2 pt-0" style="color:rgba(0,0,0,0.4) ;font-size: 18px;">{{$t('withdrawn.no_zayavok') }}</p>
    <div v-else>
      <ranking-list-card
      :horizontal-break="false"
      v-if="cards"
      :card="{
        title: '',
        item:[]
      }"
    >
      <ranking-list
        :item="requests"
        :horizontal-break="false"
      />
    </ranking-list-card>
    </div>
  </div>

  <div  class="m-4" style="border-top: 1px solid #BABDC5;"></div>

  <div class=" m-3 pb-0 ">
    <h3 class="mb-0 pb-0 text-dark" style="">{{$t('withdrawn.suc_trans') }}</h3>
    <p  v-if='transactions.length==0' class="mt-2 pt-0" style="color:rgba(0,0,0,0.4) ;font-size: 18px;">{{$t('withdrawn.otsut_trans') }}</p>
    <div class="pt-4 ps-1 " v-else>
      <div  v-for="tt in userTransactions" v-bind:key="tt.id">
        <div class="">
          <div class="" style="text-align: start;">
            <span  style="padding:3px 10px; font-weight:500;border-radius:8px; width: fit-content; background:#F6F6F8;line-height:16px; margin-left:-10px">{{ tt.portfolio.name }} </span>
            <br>
            <span style="font-weight:500">
              <template  v-if="tt.in_out==='out'">
                {{$t('withdrawn.vivod') }}
              </template>
              <template v-if="tt.in_out==='reinvest_out'" > {{$t('withdrawn.vivod') }} </template>
              <template v-if="tt.in_out==='in'">{{$t('withdrawn.vvod') }}</template>
              <template v-if="tt.in_out==='reinvest_in'">{{$t('withdrawn.vvod') }}</template>
              <template  v-if="tt.source==='reinvest'">{{$t('withdrawn.reinvest') }}</template>
              #{{ tt.id }}
            </span>
            <br>
            <span class="m-1" v-if="tt.in_out==='in' && tt.in_out === 'reinvest_in'" >
              
              {{$t('withdrawn.in_reinvest') }}
            </span>
            <span class="m-1" v-if="tt.source==='reinvest'" >
              {{$t('withdrawn.in_reinvest') }}
            </span>
          </div>

   
            
        </div>

         <div style="text-align: start;">
          <span style="font-size:25px; font-weight:900; ">{{ tt.value.toLocaleString('en') }} {{$t('withdrawn.valuta') }}</span>
          <br>
          <span class="opacity:0.5"> {{$t('withdrawn.date') }} {{ tt.date }}</span>
          

          <div  class="mt-2 mb-2" style="border-top: 1px solid #dadadb;"></div>
        </div>


      </div>
      
    </div>
  </div>
</div>
</template>

<style scoped>
.hover-big{
  transition: transform 0.2s;
  cursor: pointer;
}
@media screen and (width > 768px) {

.hover-big:hover{
  transform: scale(2);

  position: absolute;
  z-index: 9999;
}
}
.factor{
  font-family:  "Inter", sans-serif;
}
.mybtn{
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  text-align: center;

}
.bg-dark-outlined{
  border: 1px solid black;
  color:black
}
.disabled{
  cursor: not-allowed;
}
.line-robot{
  border-radius: 20px !important;
  min-height:290px;
}
.digit-text-gradient {
  font-size: 28px !important;
  background: -webkit-linear-gradient(#242121, #242121);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btnmy-withdraw{
  border-radius: 0px 0px 20px 20px;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  color:#666666;
  padding: 10px 0px;
  border: 0px solid rgba(0, 0, 0, 0);
}
</style>

<script>
// import Telegram from "./components/Telegram.vue";
import setTooltip from "@/assets/js/tooltip.js";
import RankingList from "@/examples/Cards/RankingList.vue";
import RankingListCard from "@/examples/Cards/RankingListCard.vue";

// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import { mapMutations,mapGetters,mapActions  } from "vuex";

import {
  faHandPointer,
  faUsers,

  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      change: {
        in:{
          icon: 'fa-arrow-down',
          color: 'primary',
          sub: 'Депозит'
        },
        out:{
          icon: 'fa-arrow-up',
          color: 'danger',
          sub: 'Успешный вывод'
        },
        reinvest:{
          icon: '',
          color: 'dark',
          sub: 'Транзакция'
        },
        reinvest_in:{
          icon: 'fa-arrow-left',
          color: 'info',
          sub: 'Выведено (реинвест)'
        },
        reinvest_out:{
          icon: 'fa-arrow-right',
          color: 'info',
          sub: 'Депозит (реинвест)'
        }
      },
      req: {
        in:{
          icon: 'fa-arrow-down',
          color: 'info',
          sub: 'Депозит'
        },
        out:{
          icon: 'fa-arrow-up',
          color: 'info',
          sub: this.$t('withdrawn.withdrawal_request')
        },
        reinvest:{
          icon: 'fa-arrow-up',
          color: 'dark',
          sub:this.$t('withdrawn.reinvest')
        }
      },
      iconBackground: "bg-gradient-info",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  methods: {
    ...mapMutations(["toggleConfigurator","toggleWithdraw","toggleTopUpCrypto","toggleReinvest","toggleTopUpOwn"]),
    ...mapActions(["getUserData","getTransactions","getBotsUser"]),
    withdraw(id){
      this.toggleWithdraw(id)
    },
    walert(text){
      alert(text)
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    callSupport(){
      if(this.isMobile){
        this.$toast.info("Сейчас начнется вызов", { });
        location.href='tel:+78007074730';
      }
      else{
        this.$toast.info("Вы с компьютера или с планшета, наберите номер на мобильном", { });
      }

    },
  },
  computed:{
    ...mapGetters(["user","userPlot","userTransactions","userBots","userRequests"]),
    cards(){
      return {
            title: 'Транзации',
            item:this.transactions
          }
     },
     requests(){
      var tt = []
      this.userRequests.forEach(element => {
          var t_amount = '-'
          if (element.value){
            t_amount = element.value.toLocaleString('en')
          }

          tt.push({
            title: element.portfolio.name,
            date: `${this.req[element.in_out].sub} / ${element.date}`,
            amount: `${t_amount} $`,
            icon:  this.req[element.in_out].icon,
            color: this.req[element.in_out].color,
        })
      });
      return tt

     },

     transactions(){
      var tt = []
      this.userTransactions.forEach(element => {
          var t_amount = '-'
          if (element.value){
            t_amount = element.value.toLocaleString('en')
          }
          if (element.source === 'reinvest') element.in_out =  `reinvest_${element.in_out}`
         
         var sub =  'Транзакция'
         var icon =  ''
         var color = 'dark'

          if (this.change[element.in_out]) {
            sub = this.change[element.in_out].sub 
            icon = this.change[element.in_out].icon
            color = this.change[element.in_out].color
          }

          tt.push({
            title: element.portfolio.name,
            date: `${sub} от ${element.date}`,
            amount: `${t_amount} $`,
            icon:  icon,
            color: color,
        })
      });
      return tt

     }
  },
  components: {
    // MiniStatisticsCard,
    // ReportsBarChart,
    // Telegram,
    // GradientLineChart,
    RankingList,
    RankingListCard,
    // ProjectsCard,
    // TimelineList,
    // TimelineItem,
  },
   mounted() {
    setTooltip();
    this.getTransactions()
    this.getBotsUser()
  },

  
  
};
</script>
